import { Grid, GridItem, MenuItem, Text } from "@chakra-ui/react";
import { Ico } from "@/assets/icons";

const DeviceMenuItem = ({
  isSelected,
  label,
  onClick,
}: {
  isSelected: boolean;
  label: string;
  onClick: () => void;
}) => {
  return (
    <MenuItem
      onClick={onClick}
      bg={isSelected ? "#444" : "transparent"}
      _hover={{ bg: "#444" }}
      color="white"
      px={4}
      py={2}
    >
      <Grid templateColumns="24px 1fr" gap={2} alignItems="center" w="100%">
        <GridItem>{isSelected && <Ico.Check color="#6F9FFF" />}</GridItem>
        <GridItem>
          <Text>{label}</Text>
        </GridItem>
      </Grid>
    </MenuItem>
  );
};

export default DeviceMenuItem;
