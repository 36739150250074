export enum TypeIcon {
  MUTE,
  SOUND,
  FULL_SCREEN,
  FULL_SCREEN_EXIT,
  MICROFONE_ON,
  MICROFONE_OFF,
  CAMERA_ON,
  CAMERA_OFF,
  SHARED_ON,
  SHARED_OFF,
  EndLive_ON,
  EndLive_OFF,
}

interface PropInterface {
  type: TypeIcon;
}

export const IconControlsFullScreen = ({ type }: PropInterface) => {
  return (
    <>
      {type === TypeIcon.FULL_SCREEN ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4.28571 14.5714C3.57857 14.5714 3 15.15 3 15.8571V19.7143C3 20.4214 3.57857 21 4.28571 21H8.14286C8.85 21 9.42857 20.4214 9.42857 19.7143C9.42857 19.0071 8.85 18.4286 8.14286 18.4286H5.57143V15.8571C5.57143 15.15 4.99286 14.5714 4.28571 14.5714ZM4.28571 9.42857C4.99286 9.42857 5.57143 8.85 5.57143 8.14286V5.57143H8.14286C8.85 5.57143 9.42857 4.99286 9.42857 4.28571C9.42857 3.57857 8.85 3 8.14286 3H4.28571C3.57857 3 3 3.57857 3 4.28571V8.14286C3 8.85 3.57857 9.42857 4.28571 9.42857ZM18.4286 18.4286H15.8571C15.15 18.4286 14.5714 19.0071 14.5714 19.7143C14.5714 20.4214 15.15 21 15.8571 21H19.7143C20.4214 21 21 20.4214 21 19.7143V15.8571C21 15.15 20.4214 14.5714 19.7143 14.5714C19.0071 14.5714 18.4286 15.15 18.4286 15.8571V18.4286ZM14.5714 4.28571C14.5714 4.99286 15.15 5.57143 15.8571 5.57143H18.4286V8.14286C18.4286 8.85 19.0071 9.42857 19.7143 9.42857C20.4214 9.42857 21 8.85 21 8.14286V4.28571C21 3.57857 20.4214 3 19.7143 3H15.8571C15.15 3 14.5714 3.57857 14.5714 4.28571Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M5.14286 16.5714H7.42857V18.8571C7.42857 19.4857 7.94286 20 8.57143 20C9.2 20 9.71429 19.4857 9.71429 18.8571V15.4286C9.71429 14.8 9.2 14.2857 8.57143 14.2857H5.14286C4.51429 14.2857 4 14.8 4 15.4286C4 16.0571 4.51429 16.5714 5.14286 16.5714ZM7.42857 7.42857H5.14286C4.51429 7.42857 4 7.94286 4 8.57143C4 9.2 4.51429 9.71429 5.14286 9.71429H8.57143C9.2 9.71429 9.71429 9.2 9.71429 8.57143V5.14286C9.71429 4.51429 9.2 4 8.57143 4C7.94286 4 7.42857 4.51429 7.42857 5.14286V7.42857ZM15.4286 20C16.0571 20 16.5714 19.4857 16.5714 18.8571V16.5714H18.8571C19.4857 16.5714 20 16.0571 20 15.4286C20 14.8 19.4857 14.2857 18.8571 14.2857H15.4286C14.8 14.2857 14.2857 14.8 14.2857 15.4286V18.8571C14.2857 19.4857 14.8 20 15.4286 20ZM16.5714 7.42857V5.14286C16.5714 4.51429 16.0571 4 15.4286 4C14.8 4 14.2857 4.51429 14.2857 5.14286V8.57143C14.2857 9.2 14.8 9.71429 15.4286 9.71429H18.8571C19.4857 9.71429 20 9.2 20 8.57143C20 7.94286 19.4857 7.42857 18.8571 7.42857H16.5714Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
};

export const IconControlsMicrophone = ({ type }: PropInterface) => {
  return (
    <>
      {type === TypeIcon.MICROFONE_ON ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.25362 3.18578C10.0129 2.42654 11.0426 2 12.1164 2C13.1901 2 14.2198 2.42654 14.9791 3.18578C15.7383 3.94503 16.1649 4.97478 16.1649 6.04852V10.1887C16.1649 11.2624 15.7383 12.2922 14.9791 13.0514C14.2198 13.8107 13.1901 14.2372 12.1164 14.2372C11.0426 14.2372 10.0129 13.8107 9.25362 13.0514C8.49437 12.2922 8.06783 11.2624 8.06783 10.1887V6.04852C8.06783 4.97478 8.49437 3.94503 9.25362 3.18578ZM12.1164 3.88679C11.543 3.88679 10.9932 4.11455 10.5878 4.51995C10.1824 4.92535 9.95462 5.47519 9.95462 6.04852V10.1887C9.95462 10.762 10.1824 11.3118 10.5878 11.7173C10.9932 12.1227 11.543 12.3504 12.1164 12.3504C12.6897 12.3504 13.2395 12.1227 13.6449 11.7173C14.0503 11.3118 14.2781 10.762 14.2781 10.1887V6.04852C14.2781 5.47519 14.0503 4.92535 13.6449 4.51995C13.2395 4.11455 12.6897 3.88679 12.1164 3.88679ZM4.80765 10.2902C5.32324 10.2151 5.80204 10.5723 5.87708 11.0878C6.09522 12.5867 6.84569 13.9568 7.99121 14.9477C9.13673 15.9386 10.6007 16.4839 12.1153 16.4839C13.6299 16.4839 15.0939 15.9386 16.2394 14.9477C17.3849 13.9568 18.1354 12.5867 18.3535 11.0878C18.4286 10.5723 18.9074 10.2151 19.423 10.2902C19.9386 10.3652 20.2957 10.844 20.2207 11.3596C19.9372 13.307 18.9621 15.0873 17.4738 16.3747C16.2249 17.455 14.6847 18.1277 13.0587 18.3162V21.0566C13.0587 21.5776 12.6363 22 12.1153 22C11.5943 22 11.1719 21.5776 11.1719 21.0566V18.3162C9.54596 18.1277 8.00574 17.455 6.75686 16.3747C5.26848 15.0873 4.29339 13.307 4.00996 11.3596C3.93492 10.844 4.29206 10.3652 4.80765 10.2902Z"
            fill="white"
          />
          <rect x="9.5" y="3" width="5" height="10" rx="2.5" fill="white" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.25362 3.18578C10.0129 2.42654 11.0426 2 12.1164 2C13.1901 2 14.2198 2.42654 14.9791 3.18578C15.7383 3.94503 16.1649 4.97478 16.1649 6.04852V10.1887C16.1649 11.2624 15.7383 12.2922 14.9791 13.0514C14.2198 13.8107 13.1901 14.2372 12.1164 14.2372C11.0426 14.2372 10.0129 13.8107 9.25362 13.0514C8.49437 12.2922 8.06783 11.2624 8.06783 10.1887V6.04852C8.06783 4.97478 8.49437 3.94503 9.25362 3.18578ZM12.1164 3.88679C11.543 3.88679 10.9932 4.11455 10.5878 4.51995C10.1824 4.92535 9.95462 5.47519 9.95462 6.04852V10.1887C9.95462 10.762 10.1824 11.3118 10.5878 11.7173C10.9932 12.1227 11.543 12.3504 12.1164 12.3504C12.6897 12.3504 13.2395 12.1227 13.6449 11.7173C14.0503 11.3118 14.2781 10.762 14.2781 10.1887V6.04852C14.2781 5.47519 14.0503 4.92535 13.6449 4.51995C13.2395 4.11455 12.6897 3.88679 12.1164 3.88679ZM4.80765 10.2902C5.32324 10.2151 5.80204 10.5723 5.87708 11.0878C6.09522 12.5867 6.84569 13.9568 7.99121 14.9477C9.13673 15.9386 10.6007 16.4839 12.1153 16.4839C13.6299 16.4839 15.0939 15.9386 16.2394 14.9477C17.3849 13.9568 18.1354 12.5867 18.3535 11.0878C18.4286 10.5723 18.9074 10.2151 19.423 10.2902C19.9386 10.3652 20.2957 10.844 20.2207 11.3596C19.9372 13.307 18.9621 15.0873 17.4738 16.3747C16.2249 17.455 14.6847 18.1277 13.0587 18.3162V21.0566C13.0587 21.5776 12.6363 22 12.1153 22C11.5943 22 11.1719 21.5776 11.1719 21.0566V18.3162C9.54596 18.1277 8.00574 17.455 6.75686 16.3747C5.26848 15.0873 4.29339 13.307 4.00996 11.3596C3.93492 10.844 4.29206 10.3652 4.80765 10.2902Z"
            fill="#A10331"
          />
          <rect x="9.5" y="3" width="5" height="10" rx="2.5" fill="#A10331" />
          <path
            d="M4 3L20.9706 19.9706"
            stroke="#FFD7D8"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M3 4L19.9706 20.9706"
            stroke="#A10331"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )}
    </>
  );
};

export const IconControlsCamera = ({ type }: PropInterface) => {
  return (
    <>
      {type === TypeIcon.CAMERA_ON ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path d="M3 7H21V20H3V7Z" fill="white" />
          <path d="M8 4H16V16H8V4Z" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.24172 3.46805C8.41079 3.17822 8.72107 3 9.0566 3H14.9434C15.2789 3 15.5892 3.17822 15.7583 3.46805L17.0702 5.71698H18.7925C20.5639 5.71698 22 7.15305 22 8.92453V18.2076C22 19.979 20.5639 21.4151 18.7925 21.4151H5.20755C3.43607 21.4151 2 19.979 2 18.2075V8.92453C2 7.15305 3.43607 5.71698 5.20755 5.71698H6.92984L8.24172 3.46805ZM9.59846 4.88679L8.28658 7.13573C8.11752 7.42556 7.80723 7.60377 7.4717 7.60377H5.20755C4.47811 7.60377 3.88679 8.1951 3.88679 8.92453V18.2075C3.88679 18.937 4.47812 19.5283 5.20755 19.5283H18.7925C19.5219 19.5283 20.1132 18.937 20.1132 18.2076V8.92453C20.1132 8.1951 19.5219 7.60377 18.7925 7.60377H16.5283C16.1928 7.60377 15.8825 7.42556 15.7134 7.13573L14.4015 4.88679H9.59846ZM12 10.5472C10.6453 10.5472 9.54717 11.6453 9.54717 13C9.54717 14.3547 10.6453 15.4528 12 15.4528C13.3547 15.4528 14.4528 14.3547 14.4528 13C14.4528 11.6453 13.3547 10.5472 12 10.5472ZM7.66038 13C7.66038 10.6033 9.60329 8.66038 12 8.66038C14.3967 8.66038 16.3396 10.6033 16.3396 13C16.3396 15.3967 14.3967 17.3396 12 17.3396C9.60329 17.3396 7.66038 15.3967 7.66038 13Z"
            fill="white"
          />
          <circle cx="12" cy="13" r="5" fill="#22B30C" />
          <circle cx="12" cy="13" r="3" fill="white" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.0566 2C8.72107 2 8.41079 2.17822 8.24172 2.46805L6.92984 4.71698H5.20755C3.43607 4.71698 2 6.15305 2 7.92453V17.2075C2 18.979 3.43607 20.4151 5.20755 20.4151H18.7925C20.5639 20.4151 22 18.979 22 17.2076V7.92453C22 6.15305 20.5639 4.71698 18.7925 4.71698H17.0702L15.7583 2.46805C15.5892 2.17822 15.2789 2 14.9434 2H9.0566ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
            fill="#A10331"
          />
          <path
            d="M4 2.5752L22 21.0752"
            stroke="#FFD7D8"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M3 3.5752L21 22.0752"
            stroke="#A10331"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )}
    </>
  );
};

export const IconControlsSharedScreen = ({ type }: PropInterface) => {
  return (
    <>
      {type === TypeIcon.SHARED_ON ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 5C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V7C22 5.89543 21.1046 5 20 5H4ZM12.531 8.46967C12.2381 8.17678 11.7633 8.17678 11.4704 8.46967L8.74311 11.1969C8.45021 11.4898 8.45021 11.9647 8.74311 12.2576C9.036 12.5505 9.51087 12.5505 9.80377 12.2576L11.2507 10.8107V15.3636C11.2507 15.7779 11.5865 16.1136 12.0007 16.1136C12.4149 16.1136 12.7507 15.7779 12.7507 15.3636V10.8107L14.1977 12.2576C14.4905 12.5505 14.9654 12.5505 15.2583 12.2576C15.5512 11.9647 15.5512 11.4898 15.2583 11.1969L12.531 8.46967Z"
            fill="#E2E4E7"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.3 19H12.7C17.2 19 19 17.2 19 12.7V7.3C19 2.8 17.2 1 12.7 1H7.3C2.8 1 1 2.8 1 7.3V12.7C1 17.2 2.8 19 7.3 19Z"
            fill="#00E324"
            stroke="#00E324"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.8623 8.65L9.8873 6.625L11.9123 8.65"
            stroke="#1E1E23"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.8877 6.625V12.025"
            stroke="#1E1E23"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};

export const IconControlsEnd = ({ type }: PropInterface) => {
  return (
    <>
      {type === TypeIcon.EndLive_ON ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M11.997 8C8.28706 8.00781 4.84267 8.72623 3.12463 10.3427C2.35275 11.0689 1.95436 11.9513 2.00416 13.0289C2.03736 13.6615 2.25315 14.2159 2.65154 14.5985C2.96693 14.8874 3.39852 15.0592 3.9048 14.9812L6.9923 14.4892C7.47369 14.4189 7.80568 14.2784 8.02147 14.0753C8.29536 13.8176 8.37836 13.4428 8.37836 12.9352L8.38666 12.1309C8.38666 12.006 8.44476 11.9123 8.51945 11.8342C8.61075 11.7405 8.73525 11.6936 8.83484 11.678C9.40752 11.553 10.5778 11.4281 12.0053 11.4281C13.4246 11.4281 14.5949 11.5218 15.1675 11.678C15.2588 11.7014 15.3833 11.7483 15.4746 11.8342C15.5493 11.9123 15.5991 12.006 15.6074 12.1231L15.6157 12.9352C15.624 13.4428 15.707 13.8176 15.9809 14.0753C16.1967 14.2784 16.5287 14.4189 17.0101 14.4892L20.0644 14.9733C20.5873 15.0592 21.0271 14.8796 21.3591 14.5673C21.7575 14.2081 21.9816 13.6536 21.9982 13.0133C22.0314 11.9435 21.5998 11.0611 20.8363 10.3427C19.1182 8.72623 15.7153 8 11.997 8Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M11.997 8C8.28706 8.00781 4.84267 8.72623 3.12463 10.3427C2.35275 11.0689 1.95436 11.9513 2.00416 13.0289C2.03736 13.6615 2.25315 14.2159 2.65154 14.5985C2.96693 14.8874 3.39852 15.0592 3.9048 14.9812L6.9923 14.4892C7.47369 14.4189 7.80568 14.2784 8.02147 14.0753C8.29536 13.8176 8.37836 13.4428 8.37836 12.9352L8.38666 12.1309C8.38666 12.006 8.44476 11.9123 8.51945 11.8342C8.61075 11.7405 8.73525 11.6936 8.83484 11.678C9.40752 11.553 10.5778 11.4281 12.0053 11.4281C13.4246 11.4281 14.5949 11.5218 15.1675 11.678C15.2588 11.7014 15.3833 11.7483 15.4746 11.8342C15.5493 11.9123 15.5991 12.006 15.6074 12.1231L15.6157 12.9352C15.624 13.4428 15.707 13.8176 15.9809 14.0753C16.1967 14.2784 16.5287 14.4189 17.0101 14.4892L20.0644 14.9733C20.5873 15.0592 21.0271 14.8796 21.3591 14.5673C21.7575 14.2081 21.9816 13.6536 21.9982 13.0133C22.0314 11.9435 21.5998 11.0611 20.8363 10.3427C19.1182 8.72623 15.7153 8 11.997 8Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
};

export const OnFullScreenIcon = ({ type }: PropInterface) => {
  return (
    <>
      {type === TypeIcon.FULL_SCREEN ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4.28571 14.5714C3.57857 14.5714 3 15.15 3 15.8571V19.7143C3 20.4214 3.57857 21 4.28571 21H8.14286C8.85 21 9.42857 20.4214 9.42857 19.7143C9.42857 19.0071 8.85 18.4286 8.14286 18.4286H5.57143V15.8571C5.57143 15.15 4.99286 14.5714 4.28571 14.5714ZM4.28571 9.42857C4.99286 9.42857 5.57143 8.85 5.57143 8.14286V5.57143H8.14286C8.85 5.57143 9.42857 4.99286 9.42857 4.28571C9.42857 3.57857 8.85 3 8.14286 3H4.28571C3.57857 3 3 3.57857 3 4.28571V8.14286C3 8.85 3.57857 9.42857 4.28571 9.42857ZM18.4286 18.4286H15.8571C15.15 18.4286 14.5714 19.0071 14.5714 19.7143C14.5714 20.4214 15.15 21 15.8571 21H19.7143C20.4214 21 21 20.4214 21 19.7143V15.8571C21 15.15 20.4214 14.5714 19.7143 14.5714C19.0071 14.5714 18.4286 15.15 18.4286 15.8571V18.4286ZM14.5714 4.28571C14.5714 4.99286 15.15 5.57143 15.8571 5.57143H18.4286V8.14286C18.4286 8.85 19.0071 9.42857 19.7143 9.42857C20.4214 9.42857 21 8.85 21 8.14286V4.28571C21 3.57857 20.4214 3 19.7143 3H15.8571C15.15 3 14.5714 3.57857 14.5714 4.28571Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4.28571 14.5714C3.57857 14.5714 3 15.15 3 15.8571V19.7143C3 20.4214 3.57857 21 4.28571 21H8.14286C8.85 21 9.42857 20.4214 9.42857 19.7143C9.42857 19.0071 8.85 18.4286 8.14286 18.4286H5.57143V15.8571C5.57143 15.15 4.99286 14.5714 4.28571 14.5714ZM4.28571 9.42857C4.99286 9.42857 5.57143 8.85 5.57143 8.14286V5.57143H8.14286C8.85 5.57143 9.42857 4.99286 9.42857 4.28571C9.42857 3.57857 8.85 3 8.14286 3H4.28571C3.57857 3 3 3.57857 3 4.28571V8.14286C3 8.85 3.57857 9.42857 4.28571 9.42857ZM18.4286 18.4286H15.8571C15.15 18.4286 14.5714 19.0071 14.5714 19.7143C14.5714 20.4214 15.15 21 15.8571 21H19.7143C20.4214 21 21 20.4214 21 19.7143V15.8571C21 15.15 20.4214 14.5714 19.7143 14.5714C19.0071 14.5714 18.4286 15.15 18.4286 15.8571V18.4286ZM14.5714 4.28571C14.5714 4.99286 15.15 5.57143 15.8571 5.57143H18.4286V8.14286C18.4286 8.85 19.0071 9.42857 19.7143 9.42857C20.4214 9.42857 21 8.85 21 8.14286V4.28571C21 3.57857 20.4214 3 19.7143 3H15.8571C15.15 3 14.5714 3.57857 14.5714 4.28571Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
};
