import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UseBannerFuntionsParams } from "./interfacesBannerBCMM";

export const useBannerFuntions = ({
  startDate,
  url,
}: UseBannerFuntionsParams) => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState<string>("");
  const [isSessionStarted, setIsSessionStarted] = useState(false);
  const [loading, setLoading] = useState(true);

  const calculateTimeLeft = () => {
    if (!startDate) return;

    const targetTime = new Date(startDate).getTime();
    const currentTime = Date.now();
    const difference = targetTime - currentTime;

    if (difference <= 0) {
      setIsSessionStarted(true);
      setTimeLeft("00 : 00 : 00");
      return;
    }

    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    const formatNumber = (num: number) => num.toString().padStart(2, "0");

    setTimeLeft(
      `${formatNumber(hours)} : ${formatNumber(minutes)} : ${formatNumber(
        seconds
      )}`
    );
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(interval);
  }, [startDate]);

  const handleNavigate = () => {
    if (url) {
      navigate(url);
    }
  };

  return { timeLeft, isSessionStarted, loading, handleNavigate };
};
