import { Box, Flex, useToast } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { IconControlsSharedScreen, TypeIcon } from "../IconsControls";
import ZoomClientContext from "@/components/LiveSessionsZoom/context/zoom-context";
import { isSupportWebCodecs } from "@/components/LiveSessionsZoom/utils/platform";
import { PassiveStopShareReason } from "@zoom/videosdk";
import { useVideoState } from "@/components/LiveSessionsZoom/context/videoState/useVideoState";
import { EVENTS } from "@/components/LiveSessionsZoom/types/LiveBar.type";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { UserRole } from "@/schemaTypes";
import { useUser } from "@/providers/useUser";
import { useScreenShareState } from "./hooks/useScreenShareState";

enum ErrorType {
  INVALID_OPERATION = "INVALID_OPERATION",
  PERMISSION_DENIED = "PERMISSION_DENIED",
  SYSTEM_ERROR = "SYSTEM_ERROR",
  ALREADY_SHARING = "ALREADY_SHARING",
}

enum ErrorReason {
  USER_DENY_SCREEN_SHARE = "user deny screen share",
  SYSTEM_DENIED = "system denied screen share",
  ANOTHER_USER_SHARING = "another user is sharing",
}

interface CustomError extends Error {
  type: ErrorType;
  reason?: ErrorReason;
}

export const ScreenShared = () => {
  const { hasRoles } = useUser();
  const isMentorOrAdmin = hasRoles([UserRole.Mentor, UserRole.Admin]);
  const { zmClient } = useContext(ZoomClientContext);
  const [isStartedScreen, setIsStartedScreen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const toast = useToast();
  const MEDIA_STREAM = zmClient?.getMediaStream();
  const { dispatch } = useVideoState();
  const { getStoredState, saveState } = useScreenShareState();

  const handleError = (error: CustomError) => {
    let title = t("Error");
    let description = t("An unknown error occurred");
    let status: "error" | "info" | "warning" = "error";

    switch (error.type) {
      case ErrorType.INVALID_OPERATION:
        if (error.reason === ErrorReason.USER_DENY_SCREEN_SHARE) {
          title = t("You canceled the screen sharing option.");
          status = "info";
        } else if (error.reason === ErrorReason.ANOTHER_USER_SHARING) {
          title = t("It is not possible to share your screen.");
          description = t(
            "Another user is already sharing their screen. Only one screen can be shared at a time."
          );
          status = "warning";
        }
        break;
      case ErrorType.PERMISSION_DENIED:
        title = t("It is not possible to share your screen.");
        description = t("You must allow your browser to share your screen.");
        status = "warning";
        break;
      case ErrorType.SYSTEM_ERROR:
        title = t("Failed to stop screen sharing.");
        description =
          error.message ||
          t(
            "An error occurred while stopping screen sharing. Please try again."
          );
        break;
      default:
        SBErrorPubSub.publish({
          component: "ScreenShared.tsx",
          message: `${t("Unknown error:")} ${JSON.stringify(error)}`,
          showInProd: false,
        });
    }

    toast({
      title,
      description,
      status,
      duration: 5000,
      isClosable: true,
    });
  };

  const screenSharedMainScene = async () => {
    if (!isMentorOrAdmin || !MEDIA_STREAM) return;

    const screenSharedElement = document.querySelector<HTMLCanvasElement>(
      `#my-screen-share-content-video`
    );
    const screenSharedElementCanvas = document.querySelector<HTMLCanvasElement>(
      `#my-screen-share-content-video-canvas`
    );

    if (!screenSharedElement || !screenSharedElementCanvas) {
      const error = new Error(
        t("Screen share elements not found")
      ) as CustomError;
      error.type = ErrorType.SYSTEM_ERROR;
      throw error;
    }

    try {
      setIsProcessing(true);

      // Verificar si alguien más está compartiendo
      const isAnotherUserSharing = zmClient
        ?.getAllUser()
        .some(
          (user) =>
            user.sharerOn &&
            user.userId !== zmClient.getCurrentUserInfo()?.userId
        );

      if (isAnotherUserSharing) {
        const error = new Error(
          t("Another user is currently sharing their screen")
        ) as CustomError;
        error.type = ErrorType.INVALID_OPERATION;
        error.reason = ErrorReason.ANOTHER_USER_SHARING;
        throw error;
      }

      await MEDIA_STREAM.startShareScreen(
        !isSupportWebCodecs() ? screenSharedElementCanvas : screenSharedElement,
        {
          controls: {
            systemAudio: "exclude",
          },
        }
      );

      screenSharedElement.style.display = isSupportWebCodecs()
        ? "block"
        : "none";
      screenSharedElementCanvas.style.display = isSupportWebCodecs()
        ? "none"
        : "block";

      setIsStartedScreen(true);
      dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: true });
      saveState(true);

      toast({
        title: t("You are sharing your screen."),
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      setIsStartedScreen(false);
      handleError(error as CustomError);
    } finally {
      setIsProcessing(false);
    }
  };

  const stopScreenShare = async () => {
    if (!zmClient || !MEDIA_STREAM) return;

    try {
      setIsProcessing(true);
      await MEDIA_STREAM.stopShareScreen();
      setIsStartedScreen(false);
      dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: false });
      saveState(false);

      toast({
        title: t("You have stopped sharing your screen."),
        status: "info",
        duration: 2000,
      });
    } catch (errors) {
      const error = new Error(
        t("An error occurred while stopping screen sharing. Please try again.")
      ) as CustomError;
      error.type = ErrorType.SYSTEM_ERROR;
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  const onScreenHandle = useCallback(async () => {
    if (!isMentorOrAdmin || isProcessing) return;

    if (isStartedScreen) {
      await stopScreenShare();
    } else {
      await screenSharedMainScene();
    }
  }, [isStartedScreen, isProcessing, isMentorOrAdmin]);

  useEffect(() => {
    if (!zmClient) return;

    const handlePassivelyStopShare = async (payload: {
      reason: PassiveStopShareReason;
    }) => {
      if (payload.reason === PassiveStopShareReason.StopScreenCapture) {
        await stopScreenShare();
      }
    };

    const handleUserChange = () => {
      // Verificar si el usuario actual sigue siendo el que comparte pantalla
      const currentUser = zmClient.getCurrentUserInfo();
      const isCurrentUserSharing = zmClient
        .getAllUser()
        .some((user) => user.sharerOn && user.userId === currentUser?.userId);

      if (!isCurrentUserSharing && isStartedScreen) {
        setIsStartedScreen(false);
        dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: false });
      }
    };

    zmClient.on(EVENTS.PASSIVELY_STOP_SHARE, handlePassivelyStopShare);
    zmClient.on(EVENTS.USER_UPDATED, handleUserChange);
    zmClient.on(EVENTS.USER_REMOVED, handleUserChange);

    return () => {
      zmClient.off(EVENTS.PASSIVELY_STOP_SHARE, handlePassivelyStopShare);
      zmClient.off(EVENTS.USER_UPDATED, handleUserChange);
      zmClient.off(EVENTS.USER_REMOVED, handleUserChange);
    };
  }, [zmClient, isStartedScreen]);

  // Restaurar estado al iniciar
  useEffect(() => {
    const storedState = getStoredState();
    if (storedState.lastState && isMentorOrAdmin) {
      screenSharedMainScene();
    }
  }, []);

  return (
    <Box position="relative">
      <Flex
        w="44px"
        h="44px"
        justifyContent="center"
        alignItems="center"
        borderRadius={"100px"}
        bg={isStartedScreen ? "#22B30C" : "#444448"}
        transition="all 0.2s"
        onClick={onScreenHandle}
        cursor={!isMentorOrAdmin || isProcessing ? "not-allowed" : "pointer"}
        opacity={isProcessing ? 0.7 : 1}
        _hover={{
          opacity: !isMentorOrAdmin || isProcessing ? 0.7 : 0.9,
        }}
      >
        <IconControlsSharedScreen
          type={!isStartedScreen ? TypeIcon.SHARED_ON : TypeIcon.SHARED_OFF}
        />
      </Flex>
    </Box>
  );
};
