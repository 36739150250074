import { AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";
import { BigSpinner } from "@/components/BigSpinner";
import { DetailBanner } from "@/components/Bootcamps/DetailBanner/detailBanner";
import { useEnrollmentUtils } from "@/components/Bootcamps/Enrollment/useEnrollmentUtils";
import { InfoGrid } from "@/components/Bootcamps/InfoGrid/infoGrid";
import { InfoCardData } from "@/components/Bootcamps/InfoGrid/utils";
import { BootcampCardModal } from "@/components/Bootcamps/Modal/modalCard";
import { DataProps } from "@/components/Bootcamps/Modal/utils";
import { BootcampTabs } from "@/components/Bootcamps/Tabs/tabs";
import { useUser } from "@/providers/useUser";
import {
  BootcampBannerEntry,
  BootcampClass,
  EntityName,
  UserRole,
} from "@/schemaTypes";
import DateTimeMoment from "@/utils/dateTimeFormat/dateTimeMoment";
import { noCacheHeaders } from "@/utils/headers";
import { useQuery, useSubscription } from "@apollo/client";
import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ReleaseDocument } from "../graphql/getBootcampReleaseDetail.generated";
import {
  calculateStartDateFormatted,
  calculateStartTime,
  modulesCounter,
} from "./utils";
import {
  EnrollmentDocument,
  EnrollmentSubscription,
} from "../graphql/enrollUserInMultipleCohortsSubscrition.generated";

export const BootcampsDetails = () => {
  const { t } = useTranslation();
  const { geolocation, user, hasRoles } = useUser();
  const isPremium = hasRoles([UserRole.PremiumSubscriber]);
  const navigate = useNavigate();
  const { releaseId } = useParams<"parentId" | "releaseId">();
  const { data, loading } = useQuery(ReleaseDocument, {
    variables: { bootcampReleaseId: releaseId ?? "" },
    fetchPolicy: "no-cache",
    context: { headers: noCacheHeaders },
  });
  const { fetchUserEnrollmentStatus } = useEnrollmentUtils();
  const [startTime, setStartTime] = useState<string>("");
  const [startDateFormatted, setStartDateFormatted] = useState<string>("");
  const dateTime = new DateTimeMoment();
  const bootcamp = data?.getBootcampReleaseDetails as BootcampBannerEntry;
  const [isRegistered, setIsRegistered] = useState<boolean | null>(null);

  // Define checkEnrollmentStatus outside useEffect so it can be called elsewhere
  const checkEnrollmentStatus = async () => {
    if (bootcamp?.release?.id && user?.id) {
      const status = await fetchUserEnrollmentStatus(
        bootcamp.release.id,
        user.id
      );
      setIsRegistered(status);
    }
  };

  useEffect(() => {
    if (bootcamp) {
      const bootcampStartDateMoment = moment(bootcamp?.release?.startDate);
      setStartTime(
        calculateStartTime(
          bootcampStartDateMoment,
          dateTime,
          geolocation.location
        )
      );
      setStartDateFormatted(
        calculateStartDateFormatted(
          bootcampStartDateMoment,
          dateTime,
          geolocation.location
        )
      );
    }
    if (bootcamp && user) {
      checkEnrollmentStatus();
    }
  }, [bootcamp, user]);

  useSubscription<EnrollmentSubscription>(EnrollmentDocument, {
    onSubscriptionData: () => {
      checkEnrollmentStatus();
    },
  });

  const { modulesCount, sessionsCount } = modulesCounter(
    bootcamp?.release?.classes as BootcampClass[]
  );

  const { isOpen: openModal, onOpen, onClose: handleReset } = useDisclosure();
  const [modalProps, setModalProps] = useState<DataProps>();
  const { handleEnrollment, enrollUserInBC, unblockBcUserEnroll } =
    useEnrollmentUtils();

  const handleSubmitModal = (
    selectedBootcamp: BootcampBannerEntry,
    userId: string
  ) => {
    const successMessage = t("Te has registrado exitosamente!");
    if (selectedBootcamp.releaseBlock) {
      unblockBcUserEnroll(
        selectedBootcamp,
        userId,
        successMessage,
        handleReset
      );
      return;
    }
    enrollUserInBC(selectedBootcamp, userId, successMessage, handleReset);
  };

  const onClickEnroll = () => {
    handleEnrollment(bootcamp, setModalProps, onOpen);
  };

  if (!bootcamp || loading || isRegistered === null) {
    return <BigSpinner />;
  }

  const isMastermind =
    bootcamp.release?.bootcampType === EntityName.MastermindBootcamp;

  if (!isRegistered && !isPremium) {
    navigate(`${AppRoute.Learn}`);
    return null;
  }

  if (!isRegistered && isMastermind) {
    navigate(`${AppRoute.Learn}`);
    return null;
  }

  const gridData: InfoCardData[] =
    bootcamp.parent?.bootcampType === EntityName.MastermindBootcamp
      ? [
          {
            icon: <Ico.Cast fontSize={"20px"} />,
            text: `${sessionsCount} ${t("Live Sessions")}`,
            bgColor: "#F4F6FA",
          },
          {
            icon: <Ico.Clock fontSize={"20px"} />,
            text: `${t("Each session airs at:")} ${startTime}`,
            bgColor: "#F3FCFF",
          },
          {
            icon: <Ico.Certificate fontSize={"20px"} />,
            text: t(
              "You will receive your certification upon completion of the mastermind"
            ),
            bgColor: "#FAFEF4",
          },
        ]
      : [
          {
            icon: <Ico.BookOpen fontSize={"20px"} />,
            text: `${modulesCount} ${t("Sequential modules")}`,
            bgColor: "#FFFDF6",
          },
          {
            icon: <Ico.Cast fontSize={"20px"} />,
            text: `${sessionsCount} ${t("Live Sessions")}`,
            bgColor: "#F4F6FA",
          },
          {
            icon: <Ico.Clock fontSize={"20px"} />,
            text: `${t("Each session airs at:")} ${startTime}`,
            bgColor: "#F3FCFF",
          },
          {
            icon: <Ico.Certificate fontSize={"20px"} />,
            text: t(
              "You will receive your certification upon completion of the bootcamp"
            ),
            bgColor: "#FAFEF4",
          },
        ];

  return (
    <Fragment>
      <Flex flexDirection={"column"} marginX={"auto"} maxW={"1800px"}>
        <Flex py={4} px={{ base: 4, lg: 8 }} flexDir={"column"} gap={4}>
          <Button
            gap={2}
            w={"fit-content"}
            leftIcon={<Ico.ArrowLeft width={"16px"} height={"16px"} />}
            onClick={() => {
              navigate(`${AppRoute.Learn}`);
            }}
            variant="link"
            fontSize={"14px"}
            px={0}
            py={2}
          >
            {t("Back")}
          </Button>
          <Flex flexDir={"column"} gap={6}>
            <DetailBanner
              isRegistered={isRegistered}
              bootcamp={bootcamp}
              startDateFormatted={startDateFormatted}
              onClickEnroll={onClickEnroll}
            />
            <InfoGrid gridData={gridData} />
          </Flex>
        </Flex>
        <Box flex={1} maxW={"1800px"} px={{ base: 0, lg: 8 }} pb={"92px"}>
          <BootcampTabs
            bootcamp={bootcamp}
            isRegistered={isRegistered}
            onClickEnroll={onClickEnroll}
          />
        </Box>
      </Flex>
      <BootcampCardModal
        data={modalProps}
        isOpen={openModal}
        onClose={handleReset}
        onSubmit={handleSubmitModal}
      />
    </Fragment>
  );
};
