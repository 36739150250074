import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { useContext } from "react";
import { IconControlsEnd, TypeIcon } from "../IconsControls";
import ModalEndLive from "@/pages/academy/LiveClasses/Streaming/Video/ModalEndLive";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { StopLiveSessionDocument } from "@/components/LiveSessionsZoom/graphql/stopLiveSession.generated";
import ZoomClientContext from "@/components/LiveSessionsZoom/context/zoom-context";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { t } from "i18next";

export const LeaveSession = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { zmClient, clienteRecording } = useContext(ZoomClientContext);
  const { liveSessionId } = useParams<{ liveSessionId: string }>();
  const [stopLiveSession, { loading: loadingStopLiveSession }] = useMutation(
    StopLiveSessionDocument
  );

  const stopStreaming = async () => {
    try {
      await stopLiveSession({
        variables: {
          liveSessionsId: liveSessionId || "",
        },
      });

      if (clienteRecording) {
        await clienteRecording.stopCloudRecording();
      }

      if (zmClient) {
        zmClient.leave(true);
      }
    } catch (error) {
      SBErrorPubSub.publish({
        component: "Leave.tsx line 39",
        message: t("Error ending session or pausing recording"),
        showInProd: false,
      });
    }
  };

  const handleOpenModal = () => {
    onOpen();
  };

  return (
    <Box position="relative">
      <Flex
        w="64px"
        h="44px"
        justifyContent="center"
        alignItems="center"
        borderRadius={"100px"}
        bg="#A10331"
        transition="all 0.2s"
        onClick={handleOpenModal}
        cursor="pointer"
        _hover={{ opacity: 0.9 }}
      >
        <IconControlsEnd type={TypeIcon.EndLive_ON} />
      </Flex>
      <ModalEndLive
        isOpen={isOpen}
        onClose={onClose}
        stopStreaming={stopStreaming}
        loadingStopLiveSession={loadingStopLiveSession}
        loadingStopRecording={false}
        sessionId={liveSessionId}
      />
    </Box>
  );
};
