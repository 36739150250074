import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const fontWeight = {
  extraBold: 700,
  bold: 600,
  normal: 400,
};

const base = defineStyle({
  color: "inherit",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  fontWeight: fontWeight.normal,
  lineHeight: "1.6",
});

const bold = defineStyle({
  fontSize: "16px",
  fontWeight: fontWeight.bold,
});

const caption = defineStyle({
  fontSize: "14px",
  fontWeight: fontWeight.bold,
});

const feedback = defineStyle({
  fontSize: "12px",
  fontWeight: fontWeight.normal,
});

const placeholder = defineStyle({
  fontSize: "14px",
  fontWeight: fontWeight.normal,
});

const pretitle = defineStyle({
  fontSize: "12px",
  fontWeight: fontWeight.bold,
  letterSpacing: "0.08em",
  textTransform: "uppercase",
});

const tinyBold = defineStyle({
  fontSize: "12px",
  fontWeight: fontWeight.bold,
});

const tinyExtraBold = defineStyle({
  fontSize: "12px",
  fontWeight: fontWeight.extraBold,
});

const textTheme = defineStyleConfig({
  baseStyle: base,
  variants: {
    bold,
    caption,
    feedback,
    placeholder,
    pretitle,
    tinyBold,
    tinyExtraBold,
  },
});

export { fontWeight, textTheme };
