import { AppRoute } from "@/AppRoute";
import { useMemo, useState, useEffect } from "react";
import { UseBannerBCMMProps } from "./interfacesBannerBCMM";
import { BootcampBannerEntry, EntityName } from "@/schemaTypes";

const useBannerBCMM = ({ productsBanner }: UseBannerBCMMProps) => {
  const [selectedSession, setSelectedSession] = useState<BootcampBannerEntry>(
    {}
  );
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [shouldShowBanner, setShouldShowBanner] = useState(false);

  useEffect(() => {
    const now = new Date();
    const currentDate = now.toISOString().split("T")[0];

    if (selectedDate !== currentDate) {
      const validProducts = productsBanner.filter((product) => {
        const startDate = product.release?.startDate
          ? new Date(product.release.startDate).getTime()
          : null;
        return startDate === null || startDate >= now.getTime();
      });

      const todaySessions = productsBanner.filter((product) => {
        const startDate = product.release?.startDate
          ? new Date(product.release.startDate).getTime()
          : null;
        const productDate = startDate
          ? new Date(startDate).toISOString().split("T")[0]
          : "";
        return productDate === currentDate;
      });

      let nextSession;
      if (todaySessions.length > 0) {
        const sortedTodaySessions = todaySessions.sort((a, b) => {
          const dateA = a.release?.startDate
            ? new Date(a.release.startDate).getTime()
            : Infinity;
          const dateB = b.release?.startDate
            ? new Date(b.release.startDate).getTime()
            : Infinity;

          if (dateA !== dateB) {
            return dateA - dateB;
          }

          const typeA = a.release?.bootcampType || "";
          const typeB = b.release?.bootcampType || "";

          if (
            typeA === EntityName.MastermindBootcamp &&
            typeB !== EntityName.MastermindBootcamp
          ) {
            return 1;
          }
          if (
            typeB === EntityName.MastermindBootcamp &&
            typeA !== EntityName.MastermindBootcamp
          ) {
            return -1;
          }

          return 0;
        });

        nextSession = sortedTodaySessions[0];
      } else if (validProducts.length > 0) {
        const sortedProducts = validProducts.sort((a, b) => {
          const dateA = a.release?.startDate
            ? new Date(a.release.startDate).getTime()
            : Infinity;
          const dateB = b.release?.startDate
            ? new Date(b.release.startDate).getTime()
            : Infinity;

          return dateA - dateB;
        });

        nextSession = sortedProducts[0];
      }

      if (nextSession) {
        setSelectedSession(nextSession);
        setSelectedDate(currentDate);
      }
    }
  }, [productsBanner, selectedDate]);

  useEffect(() => {
    if (!selectedSession) {
      setShouldShowBanner(false);
      return;
    }

    const interval = setInterval(() => {
      const now = Date.now();
      const startDate = selectedSession?.release?.startDate
        ? new Date(selectedSession.release.startDate).getTime()
        : null;

      if (!startDate) {
        setShouldShowBanner(false);
        return;
      }

      const timeBeforeSession = startDate - 1800000;
      const timeAfterSession = startDate + 1800000;
      const bannerVisible = now >= timeBeforeSession && now <= timeAfterSession;

      setShouldShowBanner(bannerVisible);
    }, 1000);

    return () => clearInterval(interval);
  }, [selectedSession]);

  const bannerData = useMemo(() => {
    if (!selectedSession) {
      return { shouldShowBanner: false, data: null };
    }

    const startDate = selectedSession?.release?.startDate
      ? new Date(selectedSession.release.startDate).getTime()
      : null;

    if (!startDate) {
      return { shouldShowBanner: false, data: null };
    }
    const formatTitleClass =
      selectedSession?.release?.classes?.[0]?.title?.split("-") || [];

    const objData = {
      color:
        selectedSession?.release?.bootcampType === EntityName.MastermindBootcamp
          ? "#8F8F91"
          : selectedSession?.parent?.brand?.color || "#000000",
      typeUp:
        selectedSession?.release?.bootcampType === EntityName.MastermindBootcamp
          ? "MASTERMIND"
          : "BOOTCAMP",
      typeLow:
        selectedSession?.release?.bootcampType === EntityName.MastermindBootcamp
          ? "Mastermind"
          : "Bootcamp",
      title: selectedSession?.release?.title || "",
      titleClass:
        formatTitleClass.length > 1
          ? formatTitleClass[1].split(":")[1]
          : "Session 1",
      url: `${AppRoute.Bootcamps}/${selectedSession?.parent?.id}/${selectedSession?.release?.id}`,
      startDate: selectedSession?.release?.startDate
        ? new Date(selectedSession.release.startDate).toString()
        : "",
    };

    return { shouldShowBanner, data: objData };
  }, [selectedSession, shouldShowBanner]);

  return bannerData;
};

export default useBannerBCMM;
