/* eslint-disable no-console */
import { Button } from "@/components/Button";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Stream } from "@zoom/videosdk";
import { t } from "i18next";
import { useState, useCallback, useEffect, useContext } from "react";
import ZoomContext from "../context/zoom-context";

interface modalActiveAudioProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  stream: typeof Stream | undefined | null;
}

export const ModalActiveAudio = ({
  isOpen,
  onClose,
  stream,
}: modalActiveAudioProps) => {
  const { hasRoles } = useUser();
  const { zmClient } = useContext(ZoomContext);
  const isMentor = hasRoles([UserRole.Mentor, UserRole.Admin]);
  const [loading, setLoading] = useState(false);
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const selectedDevices = localStorage.getItem("selectedDevices");

  const verifySession = useCallback(() => {
    if (!zmClient?.getSessionInfo()?.isInMeeting) {
      return false;
    }
    if (!stream) {
      return false;
    }
    return true;
  }, [zmClient, stream]);

  const activeAudio = useCallback(async () => {
    if (!verifySession()) return;

    setLoading(true);
    try {
      const devices = JSON.parse(selectedDevices || "{}");

      if (!isAudioInitialized && stream) {
        if (isMentor) {
          await stream.startAudio({
            autoStartAudioInSafari: false,
            syncButtonsOnHeadset: false,
            mute: true,
            microphoneId: devices.microphone || "default",
            speakerId: devices.speaker || "default",
          });
        } else {
          await stream.startAudio({
            autoStartAudioInSafari: false,
            speakerOnly: true,
            speakerId: devices.speaker || "default",
          });
        }
        setIsAudioInitialized(true);
      }

      // Asegurarse de que el mentor empiece muteado
      if (isMentor && stream) {
        await stream.muteAudio();
      }

      setLoading(false);
      onClose();
    } catch (error) {
      console.error("Error activating audio:", error);
      setLoading(false);
      setIsAudioInitialized(false);
    }
  }, [
    stream,
    isMentor,
    selectedDevices,
    onClose,
    isAudioInitialized,
    verifySession,
  ]);

  // Efecto para manejar el mute inicial para mentores
  useEffect(() => {
    const initializeAudioState = async () => {
      if (!verifySession()) return;

      try {
        if (isMentor && stream && !stream.isAudioMuted()) {
          await stream.muteAudio();
        }
      } catch (error) {
        console.warn("Error setting initial audio state:", error);
      }
    };

    if (isOpen) {
      initializeAudioState();
    }
  }, [isMentor, stream, isOpen, verifySession]);

  // Cleanup al desmontar
  useEffect(() => {
    return () => {
      if (stream && zmClient?.getSessionInfo()?.isInMeeting) {
        try {
          stream.muteAudio();
        } catch (error) {
          console.warn("Error in cleanup:", error);
        }
      }
    };
  }, [stream, zmClient]);

  return (
    <Modal
      size={{ base: "sm", lg: "lg" }}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        w={{ base: "90%", lg: "45%" }}
        backgroundColor={"shades.white"}
        borderRadius={"8px"}
        height="auto"
        display={"flex"}
      >
        <ModalBody>
          <Box
            display={"flex"}
            flexDirection={{ base: "column", lg: "row" }}
            alignItems={{ base: "flex-start", lg: "center" }}
            justifyContent={"center"}
            gap={2}
            h={"100%"}
          >
            <Box display={"flex"} flexDirection={"column"} gap={2} w={"100%"}>
              <Text
                color={"secundary.300"}
                fontSize={"14px"}
                fontWeight={"bold"}
              >
                👋 {t("Welcome to the Live Session!")}
              </Text>
              <Text
                color={"secundary.300"}
                fontWeight={"400"}
                fontSize={"14px"}
              >
                {t("Click to start and enjoy the session to the fullest.")}
              </Text>
            </Box>
            <Box
              w={{ base: "100%", lg: "auto" }}
              display={"flex"}
              justifyContent={{ base: "stretch", lg: "center" }}
            >
              <Button
                title={t("Entrar")}
                onClick={activeAudio}
                variant="primary"
                borderRadius={"6px"}
                fontSize={"14px"}
                isLoading={loading}
                w={{ base: "100%", lg: "auto" }}
              />
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
