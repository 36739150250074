import Amazon from "./amazon.svg?r";
import ArrowDown from "./arrow-down.svg?r";
import ArrowLeft from "./arrow-left.svg?r";
import ArrowRightContained from "./arrow-right-contained.svg?r";
import ArrowUpContained from "./arrow-up-contained.svg?r";
import ArrowUpRight from "./arrow-up-right.svg?r";
import ArrowDownContained from "./arrow-down-contained.svg?r";
import ArrowRight from "./arrow-right.svg?r";
import ArrowRotate from "./arrow-rotate.svg?r";
import ArrowsRotate from "./arrows-rotate.svg?r";
import Award from "./award.svg?r";
import Ban from "./ban.svg?r";
import Bars from "./bars.svg?r";
import Bell from "./bell.svg?r";
import Bolt from "./bolt.svg?r";
import Book from "./book.svg?r";
import BookOpen from "./book-open.svg?r";
import Box from "./box.svg?r";
import BoxCheck from "./box-check.svg?r";
import BoxMagnifyingGlass from "./box-magnifying-glass.svg?r";
import Building from "./building.svg?r";
import Calendar from "./calendar.svg?r";
import CalendarCheck from "./calendar-check.svg?r";
import CalendarDots from "./calendar-dots.svg?r";
import CameraVideo from "./camera-video.svg?r";
import Camera from "./camera.svg?r";
import Cast from "./cast.svg?r";
import Certificate from "./certificate.svg?r";
import ChalkboardStudent from "./chalkboard-student.svg?r";
import ChartBar from "./chart-bar.svg?r";
import Check from "./check.svg?r";
import ChevronLeft from "./chevron-left.svg?r";
import ChevronDoubLeft from "./chevron-double-left.svg?r";
import ChevronDoubRight from "./chevron-double-right.svg?r";
import ChevronDown from "./chevron-down.svg?r";
import ChevronRight from "./chevron-right.svg?r";
import ChevronUp from "./chevron-up.svg?r";
import Circle from "./circle.svg?r";
import Circle2 from "./circle2.svg?r";
import CircleArrowDown from "./circle-arrow-down.svg?r";
import CircleArrowLeft from "./circle-arrow-left.svg?r";
import CircleArrowRight from "./circle-arrow-right.svg?r";
import CircleArrowUp from "./circle-arrow-up.svg?r";
import CircleCheck from "./circle-check.svg?r";
import CircleChevronDown from "./circle-chevron-down.svg?r";
import CircleChevronLeft from "./circle-chevron-left.svg?r";
import CircleChevronRight from "./circle-chevron-right.svg?r";
import CircleChevronUp from "./circle-chevron-up.svg?r";
import CircleInfo from "./circle-info.svg?r";
import CirclePlay from "./circle-play.svg?r";
import CircleQuestion from "./circle-question.svg?r";
import CleanAmazon from "./clean-amazon.svg?r";
import CleanDropshipping from "./clean-dropshipping.svg?r";
import CleanMeli from "./clean-meli.svg?r";
import CircleTimes from "./circle-times.svg?r";
import CircleWarning from "./circle-warning.svg?r";
import Clock from "./clock.svg?r";
import CloudArrowUp from "./cloud-arrow-up.svg?r";
import Close from "./close.svg?r";
import Copy from "./copy.svg?r";
import CommunicationText2 from "./communication-text-2.svg?r";
import CreditCard from "./credit-card.svg?r";
import DiagramProject from "./diagram-project.svg?r";
import DownToBracket from "./down-to-bracket.svg?r";
import Dropshipping from "./dropshipping.svg?r";
import EmpityState1 from "./empity-state-1.svg?r";
import EmpityState2 from "./empity-state-2.svg?r";
import Envelop from "./envelop.svg?r";
import EyeOpen from "./eye-open.svg?r";
import EyeClosed from "./eye-closed.svg?r";
import Facebook from "./facebook.svg?r";
import File from "./file.svg?r";
import FileArrowDown from "./file-arrow-down.svg?r";
import FileCheck from "./file-check.svg?r";
import FileDown from "./file-down.svg?r";
import FilePlus from "./file-plus.svg?r";
import Filter from "./filter.svg?r";
import ForoIcon from "./foro-icon.svg?r";
import Flag from "./flag.svg?r";
import Flask from "./flask.svg?r";
import Gift from "./gift.svg?r";
import Gear from "./gear.svg?r";
import GeneralCheck from "./general-check.svg?r";
import GeneralCheckDark from "./general-check-dark.svg?r";
import Globe from "./globe.svg?r";
import GraduationCap from "./graduation-cap.svg?r";
import Hourglass from "./hourglass.svg?r";
import House from "./house.svg?r";
import IconSmartBeemoLitle from "./icon-smart-beemo-litle.svg?r";
import Image from "./image.svg?r";
import Instagram from "./instagram.svg?r";
import LaptopPlay from "./laptop-play.svg?r";
import LinkedIn from "./linkedin.svg?r";
import LinkExternal from "./link-external.svg?r";
import LocationPin from "./location-pin.svg?r";
import Lock from "./lock.svg?r";
import Lock02 from "./lock-02.svg?r";
import LockOpen from "./lock-open.svg?r";
import MagnifyingGlass from "./magnifying-glass.svg?r";
import Map from "./map.svg?r";
import Meli from "./meli.svg?r";
import MessageAlertCircle from "./message-alert-circle.svg?r";
import MessageAlert from "./message-alert.svg?r";
import MessageInfo from "./message-info.svg?r";
import MessageQuestion from "./message-question.svg?r";
import MessageText from "./message-text.svg?r";
import Minus from "./minus.svg?r";
import Microphone from "./microphone.svg?r";
import MicrophoneDisabled from "./microphone-disabled.svg?r";
import MonitorDollar from "./monitor-dollar.svg?r";
import MonitorRecorder from "./monitor-recorder.svg?r";
import Moon from "./moon.svg?r";
import NotificationBell from "./notification-bell.svg?r";
import NotificationBellActive from "./notification-bell-active.svg?r";
import PaperPlane from "./paper-plane.svg?r";
import PenToSquare from "./pen-to-square.svg?r";
import People from "./people.svg?r";
import PhoneIncoming from "./phone-incoming.svg?r";
import PlanetCursor from "./planet-cursor.svg?r";
import Play from "./play.svg?r";
import PlayFill from "./play-fill.svg?r";
import Plus from "./plus.svg?r";
import RightFromBracket from "./right-from-bracket.svg?r";
import Shield from "./shield.svg?r";
import Signal from "./signal.svg?r";
import SliderArrowIzq from "./sliderArrowIzq.svg?r";
import SliderArrowDer from "./sliderArrowDer.svg?r";
import Slack from "./slack.svg?r";
import SquareChartBar from "./square-chart-bar.svg?r";
import SquareCheck from "./square-check.svg?r";
import SquarePlay from "./square-play.svg?r";
import SquareTrendUp from "./square-trend-up.svg?r";
import Star from "./star.svg?r";
import StarFill from "./star-fill.svg?r";
import StarShooting from "./star-shooting.svg?r";
import Sun from "./sun.svg?r";
import Teacher from "./teacher.svg?r";
import Times from "./times.svg?r";
import TimeStopWatch from "./timeStopWatch.svg?r";
import Trash from "./trash.svg?r";
import TrendUp from "./trend-up.svg?r";
import TriangleWarning from "./triangle-warning.svg?r";
import Trophy from "./trophy.svg?r";
import Truck from "./truck.svg?r";
import Twitter from "./twitter.svg?r";
import User from "./user.svg?r";
import UserArrowLeft from "./user-arrow-left.svg?r";
import UserCheck from "./user-check.svg?r";
import UserHeadset from "./user-headset.svg?r";
import UserLock from "./user-lock.svg?r";
import UserPlus from "./user-plus.svg?r";
import Users from "./users.svg?r";
import VerticalDots from "./vertical-dots.svg?r";
import WalletDiamond from "./wallet-diamond.svg?r";
import YouTube from "./youtube.svg?r";
import PaymentFailed from "./payment-failed.svg?r";
import Pencil from "./pencil.svg?r";
import SilverCrystal from "./silver-crystal.svg?r";
import Spinner from "./spinner.svg?r";
import GoldCrystal from "./gold-crystal.svg?r";
import PlatinumCrystal from "./platinum-crystal.svg?r";
import Badge from "./badge.svg?r";
import Send from "./send.svg?r";
import SendIncline from "./send-incline.svg?r";
import Triangle from "./triangle.svg?r";
import Headphones from "./headphones.svg?r";
import WhatsApp from "./whatsApp.svg?r";
import WhatsappOutline from "./whatsapp-outline.svg?r";
import Talleres from "./talleres.svg?r";
import UserX from "./user-x.svg?r";
import YourMentor from "./your-mentor.svg?r";
import VolumeSlash from "./volume-slash.svg?r";
import BellNotifications from "./bell-notifications.svg?r";
import VerticalDotsFill from "./vertical-dots-fill.svg?r";
import Warning from "./warning.svg?r";
import BemmoAvatarLarge from "./beemo-ol-avatar-large.svg?r";
import BemmoAvatarMedium from "./beemo-ol-avatar-medium.svg?r";
import BemmoAvatarSmall from "./beemo-ol-avatar-small.svg?r";

type SvgrIcon = React.FC<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

/**
 * @param {string} [props.color="inherit"] Icon's color
 * @param {string} [props.fontSize="1em"] Icon's size
 * @param {string} [props.title] Icon's title for accesibility
 * @returns {SvgrIcon}
 */
const Ico = {
  Amazon,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowRotate,
  ArrowsRotate,
  ArrowDownContained,
  ArrowUpContained,
  ArrowRightContained,
  ArrowUpRight,
  Award,
  Ban,
  Bars,
  Bell,
  Bolt,
  Book,
  BookOpen,
  Box,
  BoxCheck,
  BoxMagnifyingGlass,
  Building,
  Calendar,
  CalendarCheck,
  CalendarDots,
  CameraVideo,
  Camera,
  Cast,
  Certificate,
  ChalkboardStudent,
  ChartBar,
  Check,
  ChevronDown,
  ChevronDoubLeft,
  ChevronDoubRight,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Circle,
  Circle2,
  CircleArrowDown,
  CircleArrowLeft,
  CircleArrowRight,
  CircleArrowUp,
  CircleCheck,
  CircleChevronDown,
  CircleChevronLeft,
  CircleChevronRight,
  CircleChevronUp,
  CircleInfo,
  CirclePlay,
  CircleQuestion,
  CircleTimes,
  CleanAmazon,
  CleanDropshipping,
  CleanMeli,
  Clock,
  CloudArrowUp,
  Close,
  Copy,
  CommunicationText2,
  CreditCard,
  DiagramProject,
  DownToBracket,
  Dropshipping,
  EmpityState1,
  EmpityState2,
  Envelop,
  EyeClosed,
  EyeOpen,
  Facebook,
  File,
  FileArrowDown,
  FileCheck,
  FileDown,
  FilePlus,
  Filter,
  ForoIcon,
  Flag,
  Flask,
  Gear,
  GeneralCheck,
  GeneralCheckDark,
  Gift,
  Globe,
  GraduationCap,
  Hourglass,
  House,
  IconSmartBeemoLitle,
  Image,
  Instagram,
  LaptopPlay,
  LinkedIn,
  LinkExternal,
  LocationPin,
  Lock,
  Lock02,
  LockOpen,
  MessageInfo,
  MagnifyingGlass,
  Map,
  Meli,
  MessageAlert,
  MessageAlertCircle,
  MessageQuestion,
  MessageText,
  Minus,
  Microphone,
  MonitorDollar,
  MonitorRecorder,
  Moon,
  NotificationBell,
  NotificationBellActive,
  PaperPlane,
  PaymentFailed,
  PenToSquare,
  People,
  Pencil,
  PhoneIncoming,
  PlanetCursor,
  Play,
  PlayFill,
  Plus,
  RightFromBracket,
  Shield,
  Signal,
  SliderArrowIzq,
  SliderArrowDer,
  Slack,
  Spinner,
  SquareChartBar,
  SquareCheck,
  SquarePlay,
  SquareTrendUp,
  Star,
  StarFill,
  StarShooting,
  Sun,
  Talleres,
  Times,
  TimeStopWatch,
  Trash,
  TrendUp,
  TriangleWarning,
  Triangle,
  Trophy,
  Truck,
  Twitter,
  User,
  UserArrowLeft,
  UserCheck,
  UserHeadset,
  UserLock,
  UserPlus,
  Users,
  UserX,
  VerticalDots,
  WalletDiamond,
  VerticalDotsFill,
  YouTube,
  CircleWarning,
  SilverCrystal,
  GoldCrystal,
  PlatinumCrystal,
  Badge,
  Send,
  SendIncline,
  VolumeSlash,
  Headphones,
  WhatsApp,
  WhatsappOutline,
  YourMentor,
  Teacher,
  BellNotifications,
  Warning,
  MicrophoneDisabled,
  BemmoAvatarLarge,
  BemmoAvatarMedium,
  BemmoAvatarSmall,
};

export type { SvgrIcon };
export { Ico };
