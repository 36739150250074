import { Ico } from "@/assets/icons";
import { Flex, Box, useBreakpointValue, Text, Button } from "@chakra-ui/react";
import { BannerBCMMProps } from "./interfacesBannerBCMM";
import { BigSpinner } from "../BigSpinner";
import { useBannerFuntions } from "./useBannerFuntions";

const BannerBCMM = ({ bannerData }: BannerBCMMProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { timeLeft, isSessionStarted, loading, handleNavigate } =
    useBannerFuntions({
      startDate: bannerData?.startDate,
      url: bannerData?.url,
    });

  if (!bannerData) {
    return null;
  }

  return loading ? (
    <Box h={10} display={"flex"} justifyContent={"center"} w={"100%"}>
      <BigSpinner />
    </Box>
  ) : (
    <Flex borderRadius="8px" bg={"neutral.50"} w={"100%"}>
      <Flex
        display={"flex"}
        flexDir={isMobile ? "column" : "row"}
        w="100%"
        justifyContent={isMobile ? "flex-start" : "space-between"}
        px={isMobile ? "16px" : "22px"}
        pr={isMobile ? "none" : "50px"}
        pt={isMobile ? "16px" : "22px"}
        pb={"22px"}
      >
        <Flex
          flexDirection={"column"}
          gap={"2px"}
          alignItems={"flex-start"}
          mb={isMobile ? "16px" : "none"}
        >
          <Flex justifyContent={"center"} alignItems={"center"} gap={"4px"}>
            <Flex>
              <Ico.IconSmartBeemoLitle fontSize={"28px"} />
              <Box width={"1px"} height={"25px"} bg={"#1D1D1B"} />
            </Flex>
            <Text
              mb={"2px"}
              fontFamily={"Poppins"}
              fontSize={"16px"}
              fontWeight={700}
            >
              {bannerData?.typeUp}
            </Text>
          </Flex>
          <Text
            fontFamily={"Inter"}
            fontSize={"16px"}
            fontWeight={600}
            color={"secondary.300"}
          >
            {bannerData?.title}
          </Text>
          <Text
            fontFamily={"Inter"}
            fontSize={"14px"}
            fontWeight={600}
            color={"secondary.300"}
          >
            {bannerData?.titleClass}{" "}
            <Text
              as={"span"}
              fontFamily={"Inter"}
              fontSize={"14px"}
              color={"secondary.300"}
            >
              de tu {bannerData.typeLow} está{" "}
              {isSessionStarted ? "en curso" : "por comenzar"}.
            </Text>
          </Text>
        </Flex>
        <Flex
          flexDirection={isMobile ? "column" : "row"}
          gap={"16px"}
          alignItems={isMobile ? "none" : "center"}
        >
          {!isSessionStarted ? (
            <Flex
              py={isMobile ? "none" : "3px"}
              alignItems="center"
              flexDirection={"column"}
              mt={"5px"}
            >
              <Text
                fontFamily={"Inter"}
                fontSize={"14px"}
                fontWeight={"bold"}
                color={"secondary.300"}
                minWidth="80px"
                textAlign="center"
                letterSpacing={"0.12em"}
              >
                COMENZAMOS EN
              </Text>
              <Text
                fontFamily={"Inter"}
                fontSize={"21px"}
                fontWeight={600}
                color={"secondary.300"}
                minWidth="80px"
                textAlign="center"
                letterSpacing={"0.1em"}
              >
                {timeLeft}
              </Text>
            </Flex>
          ) : (
            <Flex
              py={isMobile ? "none" : "3px"}
              alignItems="center"
              flexDirection={"column"}
              mt={"5px"}
            >
              <Text
                fontFamily={"poppins"}
                fontSize={"14px"}
                fontWeight={600}
                color={"red"}
                minWidth="80px"
                textAlign="center"
              >
                ¡YA COMENZO!
              </Text>
            </Flex>
          )}
          <Button
            height={"40px"}
            variant={"primary"}
            borderRadius="6px"
            paddingX="16px"
            paddingY="8px"
            fontSize={"14px"}
            fontFamily={"Poppins"}
            onClick={handleNavigate}
          >
            Ir al {bannerData?.typeLow}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BannerBCMM;
