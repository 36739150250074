/* eslint-disable react-refresh/only-export-components */
import { GetUserDocument } from "@/graphql/getUser.generated";
import { useQuery } from "@apollo/client";
import { createContext, useEffect, useState } from "react";

interface CommitmentContextType {
  shouldAcceptAgreement: boolean;
  agreementDuration?: number;
  commitmentLoading: boolean;
}

const commitmentContext = createContext<CommitmentContextType>({
  shouldAcceptAgreement: false,
  commitmentLoading: true,
});

const CommitmentProvider = ({ children }: { children: React.ReactNode }) => {
  const [shouldAcceptAgreement, setShouldAcceptAgreement] = useState(false);
  const [agreementDuration, setAgreementDuration] = useState<
    number | undefined
  >(undefined);

  const { data, loading } = useQuery(GetUserDocument, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.me) {
      setShouldAcceptAgreement(data.me.shouldAcceptAgreement ?? false);
      setAgreementDuration(data.me.agreementDuration ?? undefined);
    }
  }, [data]);

  return (
    <commitmentContext.Provider
      value={{
        shouldAcceptAgreement,
        agreementDuration,
        commitmentLoading: loading,
      }}
    >
      {children}
    </commitmentContext.Provider>
  );
};

export { commitmentContext, CommitmentProvider };
