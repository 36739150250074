import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import DeviceMenuButton from "./DeviceMenu/DeviceMenuButton";
import { DeviceSelectorProps } from "./types";
import { Ico } from "@/assets/icons";
import { useState } from "react";
import DeviceMenuItem from "./DeviceMenu/DeviceMenuItem";

export const DeviceSelector: React.FC<DeviceSelectorProps> = ({
  label,
  devices,
  selectedId,
  onSelect,
  isDisabled = false,
  isActive = false,
  onToggle,
  children,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Box position="relative">
      <Popover
        placement="top"
        isOpen={isPopoverOpen}
        onClose={() => {
          setIsPopoverOpen(false);
          setIsMenuOpen(false);
        }}
      >
        <PopoverTrigger>
          <Box
            cursor={isDisabled ? "not-allowed" : "pointer"}
            opacity={isDisabled ? 0.7 : 1}
            display="flex"
            alignItems="center"
            borderRadius={isActive ? "100px" : "6px"}
            bg={isActive ? "#4A5C49" : "#A10331"}
            w="88px"
            h="44px"
          >
            <Box
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!isDisabled) {
                  setIsPopoverOpen(!isPopoverOpen);
                }
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              w="44px"
              h="44px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Ico.ChevronDown
                style={{
                  transform: isPopoverOpen ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.2s ease",
                }}
                color={isActive ? "#E2E4E7" : "#FFD7D8"}
              />
            </Box>

            <Box
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onToggle?.();
              }}
              borderRadius={isActive ? "full" : "6px"}
              bg={isActive ? "#22B30C" : "#FFD7D8"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              w="44px"
              h="44px"
            >
              {children}
            </Box>
          </Box>
        </PopoverTrigger>
        <PopoverContent
          bg="#1E1E23"
          boxShadow="lg"
          w="250px"
          p={0}
          borderRadius="6px"
          border="none"
        >
          <PopoverBody px={4} py={2}>
            <Menu
              placement="bottom"
              isLazy
              isOpen={isMenuOpen}
              onOpen={() => setIsMenuOpen(true)}
              onClose={() => setIsMenuOpen(false)}
            >
              <MenuButton
                w="100%"
                textAlign="left"
                _hover={{ bg: "#32323A" }}
                borderRadius="6px"
                border="1px solid #69696C"
                bg="#303036"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMenuOpen(!isMenuOpen);
                }}
              >
                <DeviceMenuButton
                  icon={Ico.Camera}
                  label={label}
                  selectedLabel={
                    devices.find((d) => d.deviceId === selectedId)?.label ||
                    `Seleccionar ${label}`
                  }
                  isOpen={isMenuOpen}
                />
              </MenuButton>
              <MenuList
                bg="#32323A"
                border="1px solid #444"
                minW="200px"
                p={2}
                borderRadius="6px"
              >
                {devices.map((device) => (
                  <DeviceMenuItem
                    key={device.deviceId}
                    isSelected={selectedId === device.deviceId}
                    label={
                      device.label || `${label} ${devices.indexOf(device) + 1}`
                    }
                    onClick={() => onSelect(device.deviceId)}
                  />
                ))}
              </MenuList>
            </Menu>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
