import { Ico } from "@/assets/icons";
import { Box, Flex, Text } from "@chakra-ui/react";

const DeviceMenuButton = ({
  icon: Icon,
  label,
  selectedLabel,
  isOpen,
}: {
  icon: typeof Ico.Camera;
  label: string;
  selectedLabel: string;
  isOpen: boolean;
}) => {
  return (
    <Flex alignItems="center" gap={2} w="100%" px={"12px"} py={"8px"}>
      <Icon color="#6F9FFF" />
      <Box flex="1">
        <Text color="gray.400" fontSize="sm" mb={1} display={"none"}>
          {label}
        </Text>
        <Text color="white" fontSize="sm" noOfLines={1}>
          {selectedLabel}
        </Text>
      </Box>
      <Box
        transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
        transition="transform 0.2s"
      >
        <Ico.ChevronDown color="#6F9FFF" />
      </Box>
    </Flex>
  );
};

export default DeviceMenuButton;
