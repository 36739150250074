import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import ZoomClientContext from "../context/zoom-context";
import { useToast } from "@chakra-ui/react";
import { useVideoState } from "../context/videoState/useVideoState";
import { ConnectionState } from "@zoom/videosdk";
import { useTranslation } from "react-i18next";

export const useEventsZoom = (
  setstatusSession: Dispatch<SetStateAction<string>>,
  setstatusCamera: Dispatch<SetStateAction<string>>
) => {
  const { zmClient } = useContext(ZoomClientContext);
  const [newEvents, setnewEvents] = useState([{}]);
  const toast = useToast();
  const { dispatch } = useVideoState();
  const { t } = useTranslation();

  const reconnectReasons: Record<string, string> = {
    failover: t("We are trying to reconnect you."),
    "join breakout room": t("You are joining a breakout room."),
    "move to breakout room": t("You are moving to another breakout room."),
  };

  const closedReasons: Record<string, string> = {
    "ended by host": t("This session has already ended."),
  };

  const activeShareChange = async (payload: {
    state: string;
    userId: number;
  }) => {
    const stream = zmClient?.getMediaStream();
    const screenSharedElement = document.querySelector<HTMLCanvasElement>(
      `#my-screen-share-content-video-canvas`
    );

    const screenSharedElementVideo = document.querySelector<
      HTMLCanvasElement | HTMLVideoElement
    >(`#my-screen-share-content-video`);

    if (payload.state === "Inactive") {
      await stream?.stopShareView();
      dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: false });
    } else if (
      payload.state === "Active" &&
      stream &&
      screenSharedElement &&
      screenSharedElementVideo
    ) {
      screenSharedElement.style.display = "block";
      screenSharedElementVideo.style.display = "none";

      stream.startShareView(screenSharedElement, payload.userId);
      dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: true });

      setnewEvents((prev) => {
        return [...prev, payload];
      });
    }
  };

  const peerVideoStateChange = async (payload: {
    action: string;
    userId: number;
  }) => {
    const stream = zmClient?.getMediaStream();

    switch (payload.action) {
      case "Start":
        if (stream && payload.userId !== 0) {
          setstatusCamera("CONTENT");
        }
        break;
      case "Stop":
        if (!stream) return;
        setstatusCamera("NO-CONTENT");
        // eslint-disable-next-line no-case-declarations
        const detach = await stream.detachVideo(payload.userId);

        if (Array.isArray(detach)) {
          detach.forEach((e) => e.remove());
        } else {
          detach.remove();
        }

        break;
    }
  };

  const connectionChange = (payload: {
    state?: ConnectionState;
    reason: string;
  }) => {
    const { state, reason } = payload;

    if (!state) return;

    setstatusSession(state);

    switch (state) {
      case ConnectionState.Reconnecting: {
        const reconnectDescription =
          reconnectReasons[reason as keyof typeof reconnectReasons] ||
          "Tu conexión ha fallado, estamos intentando reconectarte a la sesión, esto puede tomar unos segundos.";

        toast({
          title: "Reconectando",
          description: reconnectDescription,
          status: "info",
          duration: 9000,
          isClosable: true,
        });
        break;
      }
      case ConnectionState.Fail: {
        const errorDescription =
          reconnectReasons[reason as keyof typeof reconnectReasons] ||
          closedReasons[reason as keyof typeof closedReasons] ||
          t(
            "Your connection has failed. We are trying to reconnect you to the session, this may take a few seconds."
          );

        toast({
          title: t("Connection process failed."),
          description: errorDescription,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        break;
      }
    }
  };

  const eventsZmClient = () => {
    zmClient?.on("peer-video-state-change", peerVideoStateChange);
    zmClient?.on("active-share-change", activeShareChange);
    zmClient?.on("connection-change", connectionChange);
  };

  useEffect(() => {
    eventsZmClient();

    return () => {
      zmClient?.off("peer-video-state-change", peerVideoStateChange);
      zmClient?.off("active-share-change", activeShareChange);
      zmClient?.off("connection-change", connectionChange);
    };
  }, [zmClient]);

  return {
    events: newEvents,
  };
};
