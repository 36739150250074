import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Flex,
} from "@chakra-ui/react";
import { MediaDevice } from "@zoom/videosdk";
import { Ico } from "@/assets/icons";
import { useState } from "react";
import DeviceMenuButton from "./DeviceMenu/DeviceMenuButton";
import DeviceMenuItem from "./DeviceMenu/DeviceMenuItem";

interface AudioDeviceSelectorProps {
  microphones: MediaDevice[];
  speakers: MediaDevice[];
  selectedMicId: string;
  selectedSpeakerId: string;
  onMicSelect: (deviceId: string) => Promise<void>;
  onSpeakerSelect: (deviceId: string) => Promise<void>;
  isDisabled?: boolean;
  children?: React.ReactNode;
  isActive?: boolean;
  onToggle?: () => void;
}

export const AudioDeviceSelector: React.FC<AudioDeviceSelectorProps> = ({
  microphones,
  speakers,
  selectedMicId,
  selectedSpeakerId,
  onMicSelect,
  onSpeakerSelect,
  isDisabled = false,
  children,
  isActive = false,
  onToggle,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isMicMenuOpen, setIsMicMenuOpen] = useState(false);
  const [isSpeakerMenuOpen, setIsSpeakerMenuOpen] = useState(false);

  const handlePopoverToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDisabled) {
      setIsPopoverOpen((prev) => !prev);
      setIsMicMenuOpen(false);
      setIsSpeakerMenuOpen(false);
    }
  };

  return (
    <Box position="relative">
      <Popover
        placement="top"
        isOpen={isPopoverOpen}
        onClose={() => {
          setIsPopoverOpen(false);
          setIsMicMenuOpen(false);
          setIsSpeakerMenuOpen(false);
        }}
      >
        <PopoverTrigger>
          <Box
            cursor={isDisabled ? "not-allowed" : "pointer"}
            opacity={isDisabled ? 0.7 : 1}
            display="flex"
            alignItems="center"
            borderRadius={isActive ? "100px" : "6px"}
            bg={isActive ? "#4A5C49" : "#A10331"}
            w="88px"
            h="44px"
          >
            <Box
              w="44px"
              h="44px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={handlePopoverToggle}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Ico.ChevronDown
                style={{
                  transform: isPopoverOpen ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.2s ease",
                }}
                color={isActive ? "#E2E4E7" : "#FFD7D8"}
              />
            </Box>
            <Box
              onClick={onToggle}
              borderRadius={isActive ? "full" : "6px"}
              bg={isActive ? "#22B30C" : "#FFD7D8"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              w="44px"
              h="44px"
            >
              {children}
            </Box>
          </Box>
        </PopoverTrigger>
        <PopoverContent
          bg="#1E1E23"
          boxShadow="lg"
          w="550px"
          p={0}
          borderRadius="6px"
          border="none"
        >
          <PopoverBody px={4} py={2}>
            <Flex gap={2}>
              <Menu
                placement="bottom"
                isLazy
                isOpen={isMicMenuOpen}
                onOpen={() => setIsMicMenuOpen(true)}
                onClose={() => setIsMicMenuOpen(false)}
              >
                <MenuButton
                  w="100%"
                  textAlign="left"
                  borderRight="1px solid #444"
                  _hover={{ bg: "#32323A" }}
                  borderRadius="6px"
                  border="1px solid #69696C"
                  bg="#303036"
                >
                  <DeviceMenuButton
                    icon={Ico.Microphone}
                    label="Micrófono"
                    selectedLabel={
                      microphones.find((d) => d.deviceId === selectedMicId)
                        ?.label || "Seleccionar micrófono"
                    }
                    isOpen={isMicMenuOpen}
                  />
                </MenuButton>
                <MenuList
                  bg="#32323A"
                  border="1px solid #444"
                  minW="200px"
                  p={2}
                  borderRadius="6px"
                >
                  {microphones.map((device) => (
                    <DeviceMenuItem
                      key={device.deviceId}
                      isSelected={selectedMicId === device.deviceId}
                      label={
                        device.label ||
                        `Micrófono ${microphones.indexOf(device) + 1}`
                      }
                      onClick={() => onMicSelect(device.deviceId)}
                    />
                  ))}
                </MenuList>
              </Menu>

              <Menu
                placement="bottom"
                isLazy
                isOpen={isSpeakerMenuOpen}
                onOpen={() => setIsSpeakerMenuOpen(true)}
                onClose={() => setIsSpeakerMenuOpen(false)}
              >
                <MenuButton
                  w="100%"
                  textAlign="left"
                  _hover={{ bg: "#32323A" }}
                  borderRadius="6px"
                  border="1px solid #69696C"
                  bg="#303036"
                >
                  <DeviceMenuButton
                    icon={Ico.Headphones}
                    label="Altavoz"
                    selectedLabel={
                      speakers.find((d) => d.deviceId === selectedSpeakerId)
                        ?.label || "Seleccionar altavoz"
                    }
                    isOpen={isSpeakerMenuOpen}
                  />
                </MenuButton>
                <MenuList
                  bg="#32323A"
                  border="1px solid #444"
                  minW="200px"
                  p={2}
                  borderRadius="6px"
                >
                  {speakers.map((device) => (
                    <DeviceMenuItem
                      key={device.deviceId}
                      isSelected={selectedSpeakerId === device.deviceId}
                      label={
                        device.label ||
                        `Altavoz ${speakers.indexOf(device) + 1}`
                      }
                      onClick={() => onSpeakerSelect(device.deviceId)}
                    />
                  ))}
                </MenuList>
              </Menu>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
