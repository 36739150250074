/* eslint-disable no-console */
import { useEffect, useRef, useState } from "react";
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import ZoomVideo, { MediaDevice } from "@zoom/videosdk";
import MicrophoneControl from "@/components/LiveSessionsZoom/PreSessions/Settings/Microphone/Microphone";
import CameraControl from "./Settings/Camera/Camera";
import { t } from "i18next";
import {
  IconControlsCamera,
  IconControlsMicrophone,
  TypeIcon,
} from "../LiveVideo/Controls/IconsControls";
import { AppRoute } from "@/AppRoute";
import { useParams, useSearchParams } from "react-router-dom";
import { ApolloError, useMutation } from "@apollo/client";
import { StartLiveSessionDocument } from "@/pages/academy/LiveClasses/graphql/startLiveSession.generated";
import NetworkInfo from "./NetworkInfo";
import { useCameraStorage } from "../LiveVideo/Controls/Camera/hooks/useCameraStorage";
import SpeakerControl from "./Settings/Audio/Speaker";

const SettingsDevices = () => {
  const [searchParams] = useSearchParams();
  const urlRedirect = searchParams.get("redirect");
  const { liveSessionId } = useParams<{ liveSessionId: string }>();
  const { saveCameraPreferences } = useCameraStorage();
  const toast = useToast();

  const [startLiveSession, { loading: loadingStartLiveSession }] = useMutation(
    StartLiveSessionDocument
  );
  const [isProcessingCameraToggle, setIsProcessingCameraToggle] =
    useState(false);
  const [isProcessingMicrophoneToggle, setIsProcessingMicrophoneToggle] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(false);
  const [activeMicrophone, setActiveMicrophone] = useState("");
  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [isStartedVideo, setIsStartedVideo] = useState(false);
  const [activeCamera, setActiveCamera] = useState("");
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const [activeSpeaker, setActiveSpeaker] = useState("");
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>([]);
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const [messageText, setMessageText] = useState("");

  const localAudioRef = useRef(ZoomVideo.createLocalAudioTrack());
  const localVideoRef = useRef<ReturnType<
    typeof ZoomVideo.createLocalVideoTrack
  > | null>(null);
  const previewVideoRef = useRef<HTMLVideoElement | null>(null);
  const audioElementRef = useRef<HTMLAudioElement | null>(null);
  const deviceErrorMessages: Record<string, string> = {
    AbortError: t(
      "An unexpected issue occurred while trying to access the devices. Please try again."
    ),
    InvalidStateError: t(
      "The current document is not fully active. Please reload the page."
    ),
    NotAllowedError: t(
      "You must allow your browser to use the camera and microphone. Please check and try again."
    ),
    SecurityError: t(
      "You must allow your browser to use the camera and microphone. Please check and try again."
    ),
    NotFoundError: t(
      "No available media devices were found that meet the requested criteria."
    ),
    NotReadableError: t(
      "Devices could not be accessed due to a hardware or operating system error."
    ),
    OverconstrainedError: t(
      "The requested constraints cannot be met with the available devices. Check your device settings."
    ),
    TypeError: t(
      "The device configuration is invalid, or the page context is not secure. Verify the parameters and use an HTTPS connection."
    ),
  };
  const liveSessionErrorMessages: Record<string, string> = {
    NetworkError: t(
      "Your internet connection is experiencing issues, please check and try again."
    ),
    AuthenticationError: t("You are not authenticated. Please log in again."),
    SessionNotFound: t("The live session does not exist or has been deleted."),
    StorageError: t("The settings could not be saved in local storage."),
    RedirectError: t(
      "You are trying to access a link that does not exist. Please check and try again."
    ),
    UnknownError: t("An unexpected error occurred. Please try again."),
  };
  useEffect(() => {
    const initDevices = async () => {
      setIsLoading(true);
      try {
        // Solicitar permisos primero
        await Promise.all([
          navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
              stream.getTracks().forEach((track) => track.stop());
            })
            .catch((error) => {
              console.warn("Error requesting camera permission:", error);
              const errorMessage =
                error.name === "NotAllowedError" ||
                error.name === "SecurityError"
                  ? t("Could not access the camera. Please grant permissions.")
                  : t(
                      "Could not access the camera. Please check the permissions."
                    );
              toast({
                description: errorMessage,
                status: "warning",
                duration: 5000,
              });
            }),
          navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
              stream.getTracks().forEach((track) => track.stop());
            })
            .catch((error) => {
              const errorMessage =
                error.name === "NotAllowedError" ||
                error.name === "SecurityError"
                  ? t(
                      "Could not access the microphone. Please grant permissions."
                    )
                  : t(
                      "Could not access the microphone. Please check the permissions."
                    );
              toast({
                description: t(errorMessage),
                status: "warning",
                duration: 5000,
              });
            }),
        ]);

        // Después de solicitar permisos, obtener dispositivos
        const devices = await ZoomVideo.getDevices(true);
        const [mics, cameras, speakers] = await Promise.all([
          devices.filter((device) => device.kind === "audioinput"),
          devices.filter((device) => device.kind === "videoinput"),
          devices.filter((device) => device.kind === "audiooutput"),
        ]);

        setMicList(mics);
        setCameraList(cameras);
        setSpeakerList(speakers);

        // Inicializar dispositivos predeterminados de forma asíncrona
        if (mics.length) setActiveMicrophone(mics[0].deviceId);
        if (speakers.length) setActiveSpeaker(speakers[0].deviceId);
        if (cameras.length) {
          setActiveCamera(cameras[0].deviceId);
          localVideoRef.current = ZoomVideo.createLocalVideoTrack(
            cameras[0].deviceId
          );
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          const errorMessage =
            deviceErrorMessages[
              error.name as keyof typeof deviceErrorMessages
            ] || t("Unknown error while initializing media devices.");

          toast({
            title: t("Error initializing your devices."),
            description: errorMessage,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: t("Error initializing your devices."),
            description: t("Unknown error while initializing media devices."),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
      setIsLoading(false);
    };

    initDevices();
  }, []);

  useEffect(() => {
    if (!isStartedVideo && !isMicrophoneOn) {
      setMessageText(t("The camera and microphone are turned off."));
    } else if (!isStartedVideo) {
      setMessageText(t("Your camera is turned off."));
    } else {
      setMessageText("");
    }
  }, [isStartedVideo, isMicrophoneOn]);

  const handleSpeakerChange = async (deviceId: string) => {
    setActiveSpeaker(deviceId);
    try {
      if (audioElementRef.current) {
        await audioElementRef.current.setSinkId(deviceId);
        if (audioStream) {
          audioElementRef.current.srcObject = audioStream;
          await audioElementRef.current.play();
        }
      }
    } catch (error) {
      toast({
        description: t(
          "An error occurred while switching headphones or speakers. Please check your devices and try again."
        ),
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleMicrophoneChange = async (deviceId: string) => {
    setActiveMicrophone(deviceId);
    localAudioRef.current = ZoomVideo.createLocalAudioTrack(deviceId);

    if (isMicrophoneOn) {
      try {
        await localAudioRef.current.start();
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: { exact: deviceId } },
        });
        setAudioStream(stream);

        if (audioElementRef.current) {
          audioElementRef.current.srcObject = stream;
          await audioElementRef.current.setSinkId(activeSpeaker);
          await audioElementRef.current.play();
        }
      } catch (error) {
        toast({
          description: t(
            "An error occurred while switching microphones. Please check your devices and try again."
          ),
          status: "error",
          duration: 5000,
        });
      }
    }
  };

  const handleMicrophoneToggle = async () => {
    if (isProcessingMicrophoneToggle) return;
    try {
      setIsProcessingMicrophoneToggle(true);
      const newState = !isMicrophoneOn;

      if (isMicrophoneOn) {
        await localAudioRef.current?.stop();
        audioStream?.getTracks().forEach((track) => track.stop());
        setAudioStream(null);
        if (audioElementRef.current) {
          audioElementRef.current.pause();
          audioElementRef.current.srcObject = null;
        }
      } else {
        await localAudioRef.current?.start();
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: { exact: activeMicrophone } },
        });
        setAudioStream(stream);
        if (!audioElementRef.current) {
          audioElementRef.current = new Audio();
        }
        audioElementRef.current.srcObject = stream;
        await audioElementRef.current.setSinkId(activeSpeaker);
        await audioElementRef.current.play();
      }

      setIsMicrophoneOn(newState);
      localStorage.setItem(
        `deviceState_${liveSessionId}`,
        JSON.stringify({
          ...JSON.parse(
            localStorage.getItem(`deviceState_${liveSessionId}`) || "{}"
          ),
          isMicrophoneOn: newState,
        })
      );
    } catch (error) {
      toast({
        description: t(
          "An error occurred while switching the microphone. Please check your devices and try again."
        ),
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsProcessingMicrophoneToggle(false);
    }
  };

  const handleCameraChange = async (deviceId: string) => {
    if (deviceId === activeCamera) return;
    setActiveCamera(deviceId);

    try {
      const newTrack = ZoomVideo.createLocalVideoTrack(deviceId);
      if (isStartedVideo && previewVideoRef.current) {
        await newTrack.start(previewVideoRef.current);
        await localVideoRef.current?.stop();
      }
      localVideoRef.current = newTrack;
    } catch (error) {
      toast({
        description: t(
          "An error occurred while switching the camera. Please check your devices and try again."
        ),
        status: "error",
      });
    }
  };

  const onCameraClick = async () => {
    if (!previewVideoRef.current || isProcessingCameraToggle) return;
    try {
      setIsProcessingCameraToggle(true);
      const newState = !isStartedVideo;

      if (isStartedVideo) {
        await localVideoRef.current?.stop();
      } else {
        if (!localVideoRef.current) {
          localVideoRef.current = ZoomVideo.createLocalVideoTrack(activeCamera);
        }
        await localVideoRef.current.start(previewVideoRef.current);
      }

      setIsStartedVideo(newState);
      localStorage.setItem(
        `deviceState_${liveSessionId}`,
        JSON.stringify({
          ...JSON.parse(
            localStorage.getItem(`deviceState_${liveSessionId}`) || "{}"
          ),
          isStartedVideo: newState,
        })
      );
    } catch (error) {
      toast({
        description: t(
          t(
            "An error occurred while switching the camera. Please check your devices and try again."
          )
        ),
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsProcessingCameraToggle(false);
    }
  };

  const handleEnterButtonClick = async () => {
    try {
      if (!liveSessionId) return;

      const selectedDevicesData = {
        camera: activeCamera,
        microphone: activeMicrophone,
        speaker: activeSpeaker,
        isActiveCamera: isStartedVideo,
        isActiveMicrophone: isMicrophoneOn,
        isActiveCameraShared: false,
        isActiveCameraVideo: false,
      };

      // Guardar preferencias en localStorage
      try {
        localStorage.setItem(
          "selectedDevices",
          JSON.stringify(selectedDevicesData)
        );
        saveCameraPreferences(false);
      } catch {
        throw new Error("StorageError");
      }

      // Iniciar sesión en vivo
      try {
        await startLiveSession({
          variables: { liveSessionsId: liveSessionId },
        });
      } catch (error: unknown) {
        if (error instanceof ApolloError) {
          const errorName = error.networkError
            ? "NetworkError"
            : error.message || "UnknownError";
          throw new Error(errorName);
        }
        throw new Error("UnknownError");
      }

      // Redirigir a la sesión en vivo
      try {
        window.location.replace(
          `${AppRoute.LiveSessionBroadcast}/${liveSessionId}?redirect=${urlRedirect}`
        );
      } catch {
        throw new Error("RedirectError");
      }
    } catch (error: unknown) {
      let errorMessage: string;

      if (error instanceof Error) {
        errorMessage =
          liveSessionErrorMessages[
            error.message as keyof typeof liveSessionErrorMessages
          ] || liveSessionErrorMessages.UnknownError;
      } else {
        errorMessage = liveSessionErrorMessages.UnknownError;
      }

      toast({
        title: t("Error starting the Live Session."),
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      p={8}
      bg="shades.white"
      borderRadius="8px"
      boxShadow="0px 20px 50px 0px rgba(217, 217, 217, 0.20)"
    >
      <Grid templateColumns="2fr 1fr" gap={16}>
        <Flex flexDirection="column" alignItems="center">
          <AspectRatio ratio={16 / 9} width="100%">
            <Box position="relative">
              <video
                ref={previewVideoRef}
                id="js-preview-video"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px 8px 0 0",
                  background: "rgba(0, 0, 0, 1)",
                }}
                muted
              />
              {messageText && (
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  zIndex={1}
                >
                  <Heading variant="h5" color="white" textAlign="center">
                    {messageText}
                  </Heading>
                </Box>
              )}
            </Box>
          </AspectRatio>

          <Flex
            height="60px"
            alignItems="center"
            justifyContent="center"
            backgroundColor="secondary.300"
            width="100%"
            borderRadius="0 0 8px 8px"
          >
            <Flex flexDir="column" align="center" mr={4}>
              <IconButton
                onClick={handleMicrophoneToggle}
                aria-label="Toggle Microphone"
                bgColor="transparent"
                _hover={{ bgColor: "transparent" }}
              >
                <IconControlsMicrophone
                  type={
                    isMicrophoneOn
                      ? TypeIcon.MICROFONE_ON
                      : TypeIcon.MICROFONE_OFF
                  }
                />
              </IconButton>
              <Text
                fontSize="10px"
                fontWeight="600"
                color={isMicrophoneOn ? "#00E324" : "#FF4848"}
              >
                {t(
                  isMicrophoneOn ? "Desactivar micrófono" : "Activar micrófono"
                )}
              </Text>
            </Flex>

            <Flex flexDir="column" align="center" mr={4}>
              <IconButton
                onClick={onCameraClick}
                aria-label="Toggle Camera"
                bgColor="transparent"
                _hover={{ bgColor: "transparent" }}
              >
                <IconControlsCamera
                  type={
                    isStartedVideo ? TypeIcon.CAMERA_ON : TypeIcon.CAMERA_OFF
                  }
                />
              </IconButton>
              <Text
                fontSize="10px"
                fontWeight="600"
                color={isStartedVideo ? "#00E324" : "#FF4848"}
              >
                {t(isStartedVideo ? "Desactivar cámara" : "Activar cámara")}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection="column" justifyContent="center">
          <Box mb="16px">
            <Heading
              variant="h6"
              textAlign="center"
              mb="16px"
              color="secondary.300"
            >
              {t("Are you ready to enter?")}
            </Heading>
            <Text textAlign="center" color="secondary.300">
              {t(
                "Remember to test and configure your video, microphone and audio before entering the room."
              )}
            </Text>
          </Box>

          <NetworkInfo />

          {isLoading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" color="primary.500" />
            </Flex>
          ) : (
            <>
              <CameraControl
                activeCamera={activeCamera}
                cameraList={cameraList}
                onCameraChange={handleCameraChange}
              />
              <MicrophoneControl
                activeMicrophone={activeMicrophone}
                micList={micList}
                onMicrophoneChange={handleMicrophoneChange}
              />
              <SpeakerControl
                activeSpeaker={activeSpeaker}
                speakerList={speakerList}
                onSpeakerChange={handleSpeakerChange}
              />
              <Flex justify="center" mt="24px">
                <Button
                  color="shades.white"
                  bg="primary.500"
                  fontSize="14px"
                  w="280px"
                  borderRadius="6px"
                  onClick={handleEnterButtonClick}
                  isLoading={loadingStartLiveSession}
                >
                  {t("Enter")}
                </Button>
              </Flex>
            </>
          )}
        </Flex>
      </Grid>
    </Box>
  );
};

export default SettingsDevices;
