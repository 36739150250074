/* eslint-disable no-console */
import { useCallback, useContext, useEffect, useState } from "react";
import { IconControlsMicrophone, TypeIcon } from "../IconsControls";
import ZoomMediaContext from "@/components/LiveSessionsZoom/context/media-context";
import ZoomClientContext from "@/components/LiveSessionsZoom/context/zoom-context";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";
import { AudioDeviceSelector } from "../AudioDeviceSelector";
import { MicrophoneProps } from "../types";

export const Microphone: React.FC<MicrophoneProps> = ({
  microphones,
  speakers,
  selectedMicId,
  selectedSpeakerId,
  onMicSelect,
  onSpeakerSelect,
}) => {
  const { hasRoles } = useUser();
  const isMentorOrAdmin = hasRoles([UserRole.Mentor, UserRole.Admin]);
  const [isStartedAudio, setIsStartedAudio] = useState(false);
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const { zmClient } = useContext(ZoomClientContext);
  const zoomMediaContext = useContext(ZoomMediaContext);

  const getSelectedDevices = () => {
    try {
      const selectedDevices = localStorage.getItem("selectedDevices");
      return selectedDevices ? JSON.parse(selectedDevices) : null;
    } catch {
      return null;
    }
  };

  // Función para verificar si la conexión está completamente establecida
  const isFullyConnected = useCallback(() => {
    if (!zmClient) return false;

    try {
      const isInMeeting = zmClient.getSessionInfo()?.isInMeeting;
      const isConnected = zoomMediaContext?.statusZoom === "connected";
      const mediaStream = zoomMediaContext?.mediaContext?.mediaStream;

      return (
        isInMeeting &&
        isConnected &&
        mediaStream !== null &&
        mediaStream !== undefined
      );
    } catch (error) {
      console.warn("Error checking connection status:", error);
      return false;
    }
  }, [
    zmClient,
    zoomMediaContext?.statusZoom,
    zoomMediaContext?.mediaContext?.mediaStream,
  ]);

  const initializeAudio = useCallback(async () => {
    // Verificar que estamos realmente conectados antes de inicializar
    if (!isFullyConnected()) {
      return;
    }

    try {
      const mediaStream = zoomMediaContext?.mediaContext?.mediaStream;
      if (!mediaStream) {
        return;
      }

      if (!isAudioInitialized) {
        const selectedDevices = getSelectedDevices();

        // Intentar inicializar el audio
        await mediaStream.startAudio({
          speakerId: selectedDevices?.speaker,
          mute: true, // Empezar siempre muteado
        });

        await mediaStream.muteAudio();
        setIsAudioInitialized(true);
        setIsStartedAudio(false);
      }
    } catch (error) {
      console.error("[Audio] Error initializing audio:", error);
      setIsAudioInitialized(false);
      setIsStartedAudio(false);
    }
  }, [
    zmClient,
    zoomMediaContext?.mediaContext?.mediaStream,
    isAudioInitialized,
    isFullyConnected,
  ]);

  const onMicrophoneHandle = useCallback(async () => {
    if (!isMentorOrAdmin || !zoomMediaContext?.mediaContext?.mediaStream) {
      return;
    }

    const mediaStream = zoomMediaContext.mediaContext.mediaStream;

    // Verificar que la conexión está lista
    if (
      !zmClient?.getSessionInfo().isInMeeting ||
      zoomMediaContext?.statusZoom !== "connected"
    ) {
      return;
    }

    try {
      // Si el audio no está inicializado, inicializarlo
      if (!isAudioInitialized) {
        await mediaStream.startAudio({
          speakerId: selectedSpeakerId,
          mute: true,
        });
        setIsAudioInitialized(true);
        setIsStartedAudio(false);
        return;
      }

      // Toggle del micrófono
      if (!isStartedAudio) {
        await mediaStream.unmuteAudio();
        setIsStartedAudio(true);
      } else {
        await mediaStream.muteAudio();
        setIsStartedAudio(false);
      }
    } catch (error) {
      console.error("[Audio] Error:", error);
      setIsAudioInitialized(false);
      setIsStartedAudio(false);
    }
  }, [
    isStartedAudio,
    isAudioInitialized,
    zmClient,
    zoomMediaContext,
    isMentorOrAdmin,
    selectedSpeakerId,
  ]);

  // Efecto para inicializar el audio cuando la conexión esté lista
  useEffect(() => {
    let initializationAttempt: NodeJS.Timeout;

    if (!isAudioInitialized && isFullyConnected()) {
      // Pequeño delay para asegurar que todo está listo
      initializationAttempt = setTimeout(() => {
        initializeAudio();
      }, 1000);
    }

    return () => {
      if (initializationAttempt) {
        clearTimeout(initializationAttempt);
      }

      const mediaStream = zoomMediaContext?.mediaContext?.mediaStream;
      if (mediaStream && isFullyConnected()) {
        try {
          mediaStream.muteAudio();
          setIsStartedAudio(false);
        } catch (error) {
          console.warn("[Audio] Error in cleanup:", error);
        }
      }
    };
  }, [
    isFullyConnected,
    zoomMediaContext?.mediaContext?.mediaStream,
    isAudioInitialized,
    initializeAudio,
  ]);

  // Manejar actualizaciones de usuario
  useEffect(() => {
    const handleUserUpdate = () => {
      try {
        if (!isFullyConnected()) return;

        const currentUser = zmClient?.getCurrentUserInfo();
        if (!currentUser) return;

        const users = zmClient?.getAllUser();
        if (!users) return;

        const user = users.find((u) => u.userId === currentUser.userId);
        if (user) {
          setIsStartedAudio(!user.muted);
        }
      } catch (error) {
        console.warn("[Audio] Error updating user state:", error);
      }
    };

    if (zmClient) {
      zmClient.on("user-updated", handleUserUpdate);
      return () => {
        zmClient.off("user-updated", handleUserUpdate);
      };
    }
  }, [zmClient, isFullyConnected]);

  return (
    <AudioDeviceSelector
      microphones={microphones}
      speakers={speakers}
      selectedMicId={selectedMicId}
      selectedSpeakerId={selectedSpeakerId}
      onMicSelect={onMicSelect}
      onSpeakerSelect={onSpeakerSelect}
      isDisabled={!isMentorOrAdmin}
      isActive={isStartedAudio}
      onToggle={onMicrophoneHandle}
    >
      <IconControlsMicrophone
        type={isStartedAudio ? TypeIcon.MICROFONE_ON : TypeIcon.MICROFONE_OFF}
      />
    </AudioDeviceSelector>
  );
};
