import React, { useContext, useEffect, useState, useCallback } from "react";
import { Box, Flex, useBreakpointValue, Text } from "@chakra-ui/react";
import { useEventsZoom } from "../Hooks/useEventsZoom";
import { useGetVideosUser } from "../Hooks/useGetVideosUser";
import ZoomClientContext from "../context/zoom-context";
import { LiveSessionStatus } from "@/schemaTypes";
import { CameraGrid } from "./Controls/Camera/CameraGrid";
import { useVideoState } from "../context/videoState/useVideoState";
import { useQuery } from "@apollo/client";
import { GetJwtAuthLiveSessionDocument } from "@/components/LiveSessionsZoom/graphql/getJwtAuthLiveSession.generated";
import { EVENTS } from "../types/LiveBar.type";
import { Ico } from "@/assets/icons";

interface PropsInterface {
  userID: string;
}

const STATUS_IMAGES = {
  CLASS_NOT_STARTED: "/img/cnvbg.webp",
  CAMERA_OFF: "/img/cnvbg.webp",
  CLASS_FINISHED: "/img/cnvbg.webp",
};

enum ClassStatus {
  NOT_STARTED,
  IN_PROGRESS,
  FINISHED,
}

export const LiveVideo: React.FC<PropsInterface> = ({ userID }) => {
  const { zmClient, dataSessionAuth } = useContext(ZoomClientContext);
  const { state, dispatch } = useVideoState();
  const [classStatus, setClassStatus] = useState<ClassStatus>(
    ClassStatus.NOT_STARTED
  );
  const [showCamera, setShowCamera] = useState(false);
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [, setStatusSession] = useState<string>("");
  const [, setStatusCamera] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isAllMuted, setIsAllMuted] = useState(true);

  const { data: sessionData } = useQuery(GetJwtAuthLiveSessionDocument, {
    variables: {
      liveSessionsId: dataSessionAuth?.session?.id || "",
      liveStream: true,
      isPortal1: !!userID,
      dataPortal1Input: {
        userId: userID || "",
      },
    },
    skip: !dataSessionAuth?.session?.id,
    fetchPolicy: "network-only",
  });

  useEventsZoom(setStatusSession, setStatusCamera);
  useGetVideosUser(setStatusCamera);

  const getBackgroundImage = useCallback(() => {
    if (state.isScreenShareActive) return "";
    switch (classStatus) {
      case ClassStatus.NOT_STARTED:
        return STATUS_IMAGES.CLASS_NOT_STARTED;
      case ClassStatus.IN_PROGRESS:
        return showCamera ? "" : STATUS_IMAGES.CAMERA_OFF;
      case ClassStatus.FINISHED:
        return STATUS_IMAGES.CLASS_FINISHED;
    }
  }, [state.isScreenShareActive, classStatus, showCamera]);

  // Check if all participants are muted
  const checkParticipantsAudioStatus = useCallback(() => {
    if (!zmClient) return;
    const participants = zmClient.getAllUser();
    if (participants.length > 0) {
      const allMuted = participants.every((user) => user.muted);
      setIsAllMuted(allMuted);
    }
  }, [zmClient]);

  useEffect(() => {
    if (zmClient) {
      zmClient.on("user-updated", checkParticipantsAudioStatus);
      zmClient.on("audio-change", checkParticipantsAudioStatus);
      zmClient.on("audio-muted-all", checkParticipantsAudioStatus);
      zmClient.on("audio-unmuted-all", checkParticipantsAudioStatus);

      // Chequeo inicial
      checkParticipantsAudioStatus();

      return () => {
        zmClient.off("user-updated", checkParticipantsAudioStatus);
        zmClient.off("audio-change", checkParticipantsAudioStatus);
        zmClient.off("audio-muted-all", checkParticipantsAudioStatus);
        zmClient.off("audio-unmuted-all", checkParticipantsAudioStatus);
      };
    }
  }, [zmClient, checkParticipantsAudioStatus]);

  const updateClassStatus = useCallback(() => {
    if (!zmClient || !sessionData?.getJwtAuthLiveSession) return;

    const participants = zmClient.getAllUser();
    const hasHostOrCoHost = participants.some(
      (user) => user.isHost || user.isManager
    );
    const hasActiveCamera = participants.some((user) => user.bVideoOn);
    const sessionStatus = sessionData.getJwtAuthLiveSession.session?.status;
    const isScreenShared = zmClient.getAllUser().some((user) => user.sharerOn);

    switch (sessionStatus) {
      case LiveSessionStatus.Live:
        setClassStatus(ClassStatus.IN_PROGRESS);
        setShowCamera(hasActiveCamera);
        break;
      case LiveSessionStatus.Ended:
        setClassStatus(ClassStatus.FINISHED);
        setShowCamera(false);
        break;
      case LiveSessionStatus.Scheduled:
        if (hasHostOrCoHost && (hasActiveCamera || isScreenShared)) {
          setClassStatus(ClassStatus.IN_PROGRESS);
          setShowCamera(hasActiveCamera);
        } else {
          setClassStatus(ClassStatus.NOT_STARTED);
          setShowCamera(false);
        }
        break;
      default:
        setClassStatus(ClassStatus.NOT_STARTED);
        setShowCamera(false);
    }

    dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: isScreenShared });
  }, [zmClient, sessionData, dispatch]);

  useEffect(() => {
    const handleUserChange = () => {
      updateClassStatus();
      checkParticipantsAudioStatus();
    };
    const handleVideoActiveChange = () => updateClassStatus();
    const handleActiveShareChange = (payload: { state: boolean }) => {
      dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: payload.state });
      updateClassStatus();
    };

    if (zmClient) {
      zmClient.on(EVENTS.USER_ADDED, handleUserChange);
      zmClient.on(EVENTS.USER_REMOVED, handleUserChange);
      zmClient.on(EVENTS.USER_UPDATED, handleUserChange);
      zmClient.on(EVENTS.HOST_CHANGED, handleUserChange);
      zmClient.on(EVENTS.VIDEO_ACTIVE_CHANGE, handleVideoActiveChange);
      zmClient.on(EVENTS.ACTIVE_SHARE_CHANGE, handleActiveShareChange);
      zmClient.on("audio-state-change", checkParticipantsAudioStatus);
    }

    return () => {
      if (zmClient) {
        zmClient.off(EVENTS.USER_ADDED, handleUserChange);
        zmClient.off(EVENTS.USER_REMOVED, handleUserChange);
        zmClient.off(EVENTS.USER_UPDATED, handleUserChange);
        zmClient.off(EVENTS.HOST_CHANGED, handleUserChange);
        zmClient.off(EVENTS.VIDEO_ACTIVE_CHANGE, handleVideoActiveChange);
        zmClient.off(EVENTS.ACTIVE_SHARE_CHANGE, handleActiveShareChange);
        zmClient.off("audio-state-change", checkParticipantsAudioStatus);
      }
    };
  }, [zmClient, updateClassStatus, dispatch, checkParticipantsAudioStatus]);

  useEffect(() => {
    updateClassStatus();
  }, [updateClassStatus, sessionData]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  useEffect(() => {
    if (zmClient) {
      const isScreenShared = zmClient
        .getAllUser()
        .some((user) => user.sharerOn);
      dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: isScreenShared });
    }
  }, [zmClient, dispatch]);

  const fullscreenStyle = isFullScreen
    ? {
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
      }
    : {};

  const avatarSize = useBreakpointValue({
    base: "large", // móvil
    sm: "large", // tablet pequeña
    md: "medium", // tablet
    lg: "medium", // desktop
    xl: "small", // pantallas grandes
  });

  const getAvatarComponent = () => {
    switch (avatarSize) {
      case "large":
        return <Ico.BemmoAvatarLarge fontSize="112px" />;
      case "medium":
        return <Ico.BemmoAvatarMedium fontSize="112px" />;
      case "small":
        return <Ico.BemmoAvatarSmall fontSize="112px" />;
      default:
        return <Ico.BemmoAvatarMedium fontSize="112px" />;
    }
  };

  const shouldShowAvatar = !showCamera && !state.isScreenShareActive;

  return (
    <Box height="100%" id="LiveVideoContainer" sx={fullscreenStyle}>
      <Box
        flexDirection={{ base: "column", lg: "row" }}
        id="StreamingVideo"
        border={!isMobile ? undefined : "1px solid #32323A"}
        height="100%"
        borderTopRadius="8px"
        position="relative"
        overflow="hidden"
      >
        {/* Live Status Indicator */}
        <Flex
          position="absolute"
          top="16px"
          left="16px"
          bg="#A10331"
          color="white"
          px="3"
          py="1"
          borderRadius="md"
          alignItems="center"
          gap="2"
          zIndex="10"
        >
          <Ico.Circle2 fontSize={16} color="#FFFFFF" />
          <Text as={"span"} fontSize={"14px"} fontWeight={"600"}>
            EN VIVO
          </Text>
        </Flex>

        {/* Mute Status Indicator */}
        {isAllMuted && (
          <Flex
            position="absolute"
            top="16px"
            right="16px"
            zIndex="10"
            bg="#444448"
            w="44px"
            h="44px"
            borderRadius="full"
            justifyContent="center"
            alignItems="center"
          >
            <Ico.MicrophoneDisabled fontSize={24} color="#FFFFFF" />
          </Flex>
        )}

        <Box
          id="ContainerVideoImagenBackground"
          w="100%"
          h="100%"
          display="flex"
          flexDirection="column"
          backgroundImage={`url(${getBackgroundImage()})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          justifyContent="flex-end"
          borderTopRadius="8px"
          position="absolute"
          top="0"
          left="0"
          opacity={state.isScreenShareActive ? 0 : 1}
          transition="opacity 0.3s ease-in-out"
        />

        {/* Central Avatar */}
        {shouldShowAvatar && (
          <Flex
            id="AvatarContainer"
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            justifyContent="center"
            alignItems="center"
            zIndex="5"
          >
            {getAvatarComponent()}
          </Flex>
        )}

        <Flex
          id="content-container"
          width="100%"
          height="100%"
          overflow="hidden"
          flexDirection="row"
          justifyContent={
            state.isScreenShareActive && !showCamera ? "center" : "flex-start"
          }
          alignItems="center"
          position="relative"
        >
          <Box
            width={
              state.isScreenShareActive ? (showCamera ? "85%" : "100%") : "0%"
            }
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            transition="width 0.3s ease-in-out"
            overflow="hidden"
          >
            <video
              id="my-screen-share-content-video"
              style={{
                display: "none",
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
            <canvas
              id="my-screen-share-content-video-canvas"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>

          {showCamera && (
            <Flex
              id="camera-container"
              width={state.isScreenShareActive ? "15%" : "100%"}
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              height="100%"
              transition="width 0.3s ease-in-out"
            >
              <Box w="100%" h="100%" display="flex" alignItems="center">
                <CameraGrid userId={zmClient?.getCurrentUserInfo()?.userId} />
              </Box>
            </Flex>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default LiveVideo;
