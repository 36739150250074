/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react";
import { IconControlsCamera, TypeIcon } from "../IconsControls";
import useCamera from "@/components/LiveSessionsZoom/Hooks/useCamera";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { t } from "i18next";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";
import { useCameraStorage } from "./hooks/useCameraStorage";
import { DeviceSelector } from "../DeviceSelector";
import { DeviceSelectorCameraProps } from "../types";

export const Camera: React.FC<DeviceSelectorCameraProps> = ({
  devices,
  selectedId,
  onSelect,
}) => {
  const { hasRoles } = useUser();
  const isMentorOrAdmin = hasRoles([UserRole.Mentor, UserRole.Admin]);
  const { isVideoActive, toggleVideo, isStreamProcessing } = useCamera();
  const { getCameraPreferences, saveCameraPreferences } = useCameraStorage();
  const [hasInitialized, setHasInitialized] = useState(false);

  // Inicializar el estado de la cámara desde localStorage
  useEffect(() => {
    if (!hasInitialized && isMentorOrAdmin) {
      const preferences = getCameraPreferences();
      if (preferences.isVideoActive !== isVideoActive) {
        toggleVideo().catch((error) => {
          console.error("Error initializing camera state:", error);
        });
      }
      setHasInitialized(true);
    }
  }, [
    hasInitialized,
    isMentorOrAdmin,
    isVideoActive,
    toggleVideo,
    getCameraPreferences,
  ]);

  // Guardar cambios en localStorage
  useEffect(() => {
    if (hasInitialized && isMentorOrAdmin) {
      saveCameraPreferences(isVideoActive);
    }
  }, [hasInitialized, isMentorOrAdmin, isVideoActive, saveCameraPreferences]);

  const onCameraHandle = useCallback(async () => {
    if (!isMentorOrAdmin || isStreamProcessing) {
      return;
    }

    try {
      await toggleVideo();
      // El efecto anterior se encargará de guardar en localStorage
    } catch (error: any) {
      console.error("[Camera Component] Error:", error);
      SBErrorPubSub.publish({
        component: "Camera.tsx",
        message:
          error.message ||
          t(
            "No se pudo cambiar el estado de la cámara. Por favor, verifique la conexión y los permisos."
          ),
        showInProd: true,
      });
    }
  }, [toggleVideo, isMentorOrAdmin, isStreamProcessing]);

  return (
    <DeviceSelector
      label="Cámara"
      devices={devices}
      selectedId={selectedId}
      onSelect={onSelect}
      isDisabled={!isMentorOrAdmin || isStreamProcessing}
      isActive={isVideoActive}
      onToggle={onCameraHandle}
    >
      <IconControlsCamera
        type={isVideoActive ? TypeIcon.CAMERA_ON : TypeIcon.CAMERA_OFF}
      />
    </DeviceSelector>
  );
};
