/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { useCallback, useContext, useEffect, useState } from "react";
import ZoomClientContext from "../context/zoom-context";
import ZoomMediaContext from "../context/media-context";
import { useVideoState } from "../context/videoState/useVideoState";

const useCamera = () => {
  const { zmClient } = useContext(ZoomClientContext);
  const zoomMediaContext = useContext(ZoomMediaContext);
  const { dispatch } = useVideoState();
  const [isVideoActive, setIsVideoActive] = useState(false);
  const [isOtherUserVideoActive, setIsOtherUserVideoActive] = useState(false);
  const [maxCamerasAllowed] = useState(true);
  const [isStreamProcessing, setIsStreamProcessing] = useState(false);

  const validateCurrentUser = () => {
    try {
      const currentUserInfo = zmClient?.getCurrentUserInfo();
      if (!currentUserInfo) {
        console.warn("[Camera] No current user info available");
        return null;
      }

      const userId = currentUserInfo.userId;
      if (typeof userId !== "number" || userId <= 0) {
        console.error("[Camera] Invalid userId detected:", userId);
        return null;
      }

      return userId;
    } catch (error) {
      console.error("[Camera] Error validating current user:", error);
      return null;
    }
  };

  const validateMediaStream = (mediaStream: any) => {
    if (!mediaStream) {
      console.error("[Camera] Media stream not available");
      throw new Error("MEDIA_STREAM_NOT_AVAILABLE");
    }

    if (
      typeof mediaStream.startVideo !== "function" ||
      typeof mediaStream.isCapturingVideo !== "function"
    ) {
      console.error("[Camera] Invalid media stream interface");
      throw new Error("INVALID_MEDIA_STREAM");
    }

    return true;
  };

  const handleCameraError = (error: any): string => {
    console.error("[Camera] Detailed error:", {
      message: error.message,
      type: error.type,
      code: error.code,
      name: error.name,
    });

    if (
      error.name === "NotAllowedError" ||
      error.message?.includes("Permission") ||
      error.code === 1001
    ) {
      return "No hay permiso para acceder a la cámara. Por favor, habilite el acceso en su navegador y recargue la página.";
    }

    if (
      error.name === "NotFoundError" ||
      error.message?.includes("device not found") ||
      error.code === 1002
    ) {
      return "No se detectó ninguna cámara. Por favor, conecte una cámara y recargue la página.";
    }

    if (
      error.name === "NotReadableError" ||
      error.message?.includes("in use") ||
      error.code === 1003
    ) {
      return "La cámara está siendo utilizada por otra aplicación. Por favor, cierre otras aplicaciones que puedan estar usando la cámara.";
    }

    if (error.name === "OverconstrainedError" || error.code === 1004) {
      return "La configuración de la cámara no es compatible. Por favor, intente con otra cámara.";
    }

    if (
      error.message === "MEDIA_STREAM_NOT_AVAILABLE" ||
      error.message === "INVALID_MEDIA_STREAM"
    ) {
      return "Error al inicializar el sistema de video. Por favor, recargue la página.";
    }

    return "No se pudo activar la cámara. Por favor, verifique la conexión y los permisos.";
  };

  const waitForVideoState = async (
    mediaStream: any,
    expectedState: boolean
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      let checkAttempts = 0;
      const maxAttempts = 20; // 10 segundos máximo (500ms * 20)

      const checkVideoState = () => {
        try {
          const currentState = mediaStream.isCapturingVideo();

          if (currentState === expectedState) {
            resolve();
          } else if (checkAttempts >= maxAttempts) {
            console.error("[Camera] Timeout waiting for video state change");
            reject(
              new Error("Timeout esperando el cambio de estado de la cámara")
            );
          } else {
            checkAttempts++;
            setTimeout(checkVideoState, 500);
          }
        } catch (error) {
          console.error("[Camera] Error checking video state:", error);
          reject(error);
        }
      };

      checkVideoState();
    });
  };

  const toggleVideo = useCallback(async () => {
    if (!zmClient) {
      console.error("[Camera] Zoom client not initialized");
      throw new Error("Cliente Zoom no inicializado");
    }

    if (isStreamProcessing) {
      throw new Error(
        "Por favor, espere a que se complete la operación actual de la cámara"
      );
    }

    try {
      setIsStreamProcessing(true);

      const mediaStream = zmClient.getMediaStream();
      validateMediaStream(mediaStream);

      if (isVideoActive) {
        await mediaStream.stopVideo();
        await waitForVideoState(mediaStream, false);
        setIsVideoActive(false);
        dispatch({ type: "SET_IS_VIDEO_ACTIVE", payload: false });
      } else {
        const participants = zmClient.getAllUser();
        const activeCameras = participants.filter(
          (user) => user && typeof user.bVideoOn === "boolean" && user.bVideoOn
        ).length;

        if (maxCamerasAllowed && activeCameras >= 3) {
          throw new Error("Máximo de 3 cámaras activas alcanzado");
        }
        const isSupportHDVideo = mediaStream.isSupportHDVideo();

        try {
          const selectedDevices = JSON.parse(
            localStorage.getItem("selectedDevices") || "{}"
          );
          const cameraId = selectedDevices.camera;

          if (cameraId) {
            await mediaStream.startVideo({
              hd: isSupportHDVideo,
              cameraId,
            });
          } else {
            await mediaStream.startVideo({ hd: isSupportHDVideo });
          }

          await waitForVideoState(mediaStream, true);
          setIsVideoActive(true);
          dispatch({ type: "SET_IS_VIDEO_ACTIVE", payload: true });
        } catch (storageError) {
          console.error("[Camera] Error with stored settings:", storageError);
          await mediaStream.startVideo({ hd: isSupportHDVideo });
          await waitForVideoState(mediaStream, true);
        }
      }
    } catch (error: any) {
      console.error("[Camera] Error in toggleVideo:", error);
      setIsVideoActive(false);
      dispatch({ type: "SET_IS_VIDEO_ACTIVE", payload: false });
      const errorMessage = handleCameraError(error);
      throw new Error(errorMessage);
    } finally {
      setIsStreamProcessing(false);
    }
  }, [
    isVideoActive,
    zmClient,
    maxCamerasAllowed,
    dispatch,
    isStreamProcessing,
  ]);

  useEffect(() => {
    const checkOtherUserVideoStatus = () => {
      try {
        const currentUserId = validateCurrentUser();
        if (!currentUserId) return;

        const participants = zmClient?.getAllUser() || [];

        const otherUserVideoActive = participants.some((user) => {
          if (
            !user ||
            typeof user.userId !== "number" ||
            typeof user.bVideoOn !== "boolean"
          ) {
            console.warn("[Camera] Invalid user data detected:", user);
            return false;
          }
          return user.bVideoOn && user.userId !== currentUserId;
        });

        setIsOtherUserVideoActive(otherUserVideoActive);
        dispatch({
          type: "SET_IS_OTHER_USER_VIDEO_ACTIVE",
          payload: otherUserVideoActive,
        });
      } catch (error) {
        console.error(
          "[Camera] Error checking other user video status:",
          error
        );
      }
    };

    if (!zmClient) {
      console.warn(
        "[Camera] Zoom client not initialized for video status check"
      );
      return;
    }

    zmClient.on("user-updated", checkOtherUserVideoStatus);
    zmClient.on("user-added", checkOtherUserVideoStatus);
    zmClient.on("user-removed", checkOtherUserVideoStatus);

    return () => {
      zmClient.off("user-updated", checkOtherUserVideoStatus);
      zmClient.off("user-added", checkOtherUserVideoStatus);
      zmClient.off("user-removed", checkOtherUserVideoStatus);
    };
  }, [zmClient, dispatch]);

  useEffect(() => {
    const checkMyVideoStatus = async () => {
      try {
        if (!zmClient || zoomMediaContext?.loadingZoom) {
          return;
        }

        const stream = zoomMediaContext?.mediaContext.mediaStream;
        if (!stream) {
          console.warn("[Camera] Media stream not available");
          return;
        }
        const videoActive = stream.isCapturingVideo();

        if (videoActive !== undefined && videoActive !== isVideoActive) {
          setIsVideoActive(videoActive);
          dispatch({
            type: "SET_IS_VIDEO_ACTIVE",
            payload: videoActive,
          });
          dispatch({
            type: "SET_GRID_CAMERA",
            payload: videoActive ? 1 : 2,
          });
        }
      } catch (error) {
        console.error("[Camera] Error checking my video status:", error);
      }
    };

    checkMyVideoStatus();
  }, [zmClient, zoomMediaContext?.loadingZoom, isVideoActive, dispatch]);

  return {
    isVideoActive,
    isOtherUserVideoActive,
    toggleVideo,
    isStreamProcessing,
  };
};

export default useCamera;
