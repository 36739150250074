import React, { useEffect, ReactNode } from "react";
import { Userpilot } from "userpilot";
import { useUser } from "../providers/useUser";
import { config } from "@/config";

// Initialize Userpilot
Userpilot.initialize(config.UserpilotToken);

interface UserpilotIntegrationProps {
  children: ReactNode;
}

interface User {
  id: string;
  name: string;
  email: string;
  createdAt: string;
}

const UserpilotIntegration: React.FC<UserpilotIntegrationProps> = ({
  children,
}) => {
  const { user } = useUser() as { user: User | null };

  useEffect(() => {
    if (user) {
      Userpilot.identify(user.id, {
        name: user.name,
        email: user.email,
        created_at: user.createdAt,
      });
    }
  }, [user]);

  return <>{children}</>;
};

export default UserpilotIntegration;
