import { Avatar, Box, Flex, TabPanel, Text } from "@chakra-ui/react";
import { ChatMessage } from "@zoom/videosdk";
import InputChat from "../../Inputs/InputChat";
import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { Ico } from "@/assets/icons";
import { useUser } from "@/providers/useUser";

interface LiveChatProps {
  generalChatMessages: ChatMessage[];
  sendMessage: (isPublic: boolean, message: string) => void;
}

const LiveChat: React.FC<LiveChatProps> = (props) => {
  const { generalChatMessages, sendMessage } = props;
  const { user } = useUser();
  const [inputValue, setInputValue] = useState("");
  const [scrollChange, setscrollChange] = useState<HTMLDivElement | null>();
  const [showNewMessageNotification, setShowNewMessageNotification] =
    useState(false);
  const [lastSeenMessageId, setLastSeenMessageId] = useState<string | null>(
    null
  );
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    const el = scrollRef.current;
    if (el) {
      el.scrollTop = el.scrollHeight;
      setShowNewMessageNotification(false);
      setLastSeenMessageId(
        generalChatMessages[generalChatMessages.length - 1]?.id || null
      );
    }
  };

  const handleSendMessage = (message: string) => {
    sendMessage(true, message);
    setInputValue("");
  };

  const renderMessage = (message: string) => {
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    const parts = message.split(linkRegex);

    return parts.map((part, index) => {
      if (part.match(linkRegex)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#088080", textDecoration: "underline" }}
          >
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };

  useEffect(() => {
    if (generalChatMessages.length > 0) {
      const lastMessage = generalChatMessages[generalChatMessages.length - 1];
      const isMessageFromCurrentUser =
        lastMessage.sender.name === `${user?.name} ${user?.lastName}`;

      if (isMessageFromCurrentUser) {
        scrollToBottom();
      } else {
        const el = scrollRef.current;
        if (el) {
          const isUserScrolledUp =
            el.scrollTop < el.scrollHeight - el.clientHeight;
          if (isUserScrolledUp && lastMessage.id !== lastSeenMessageId) {
            setShowNewMessageNotification(true);
          }
        }
      }
    }
  }, [generalChatMessages, user, lastSeenMessageId]);

  const handleScroll = () => {
    const el = scrollRef.current;
    setscrollChange(el);
    if (el) {
      const isAtBottom = el.scrollHeight - el.scrollTop === el.clientHeight;
      if (isAtBottom) {
        setShowNewMessageNotification(false);
        scrollToBottom();
      }
    }

    if (el) {
      const isAtBottom = el.scrollHeight - el.scrollTop === el.clientHeight;
      if (isAtBottom) {
        setShowNewMessageNotification(false);
        setLastSeenMessageId(
          generalChatMessages[generalChatMessages.length - 1]?.id || null
        );
      }
    }
  };

  useEffect(() => {
    const el = scrollRef.current;
    if (!el) return;

    const isScrolledToBottom =
      el.scrollHeight - el.scrollTop - el.clientHeight < 100;

    if (isScrolledToBottom) {
      scrollToBottom();
    }
  }, [generalChatMessages, scrollChange]);

  return (
    <TabPanel id="LiveChat-TabPanel" height={"100%"} px={"0px"} py={"16px"}>
      <Flex direction="column" h="100%" id="LiveChat-flex">
        <Box
          onScroll={handleScroll}
          ref={scrollRef}
          css={{
            "&::-webkit-scrollbar": {
              width: "0",
            },
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&:hover": {
              "&::-webkit-scrollbar": {
                width: "3px",
              },
              scrollbarWidth: "auto",
            },
            "&::-webkit-scrollbar-thumb": {
              opacity: 0,
              transition: "opacity 0.3s",
            },
            "&:hover::-webkit-scrollbar-thumb": {
              opacity: 0.5,
            },
          }}
          overflowY="auto"
          h={"100%"}
        >
          {generalChatMessages.map(
            (message) =>
              message.message && (
                <Flex alignItems={"flex-start"} key={message.id} mb={"16px"}>
                  <Box mr={"8px"}>
                    <Avatar
                      size={"sm"}
                      name={message.sender.name.split(" ")[0]}
                    />
                  </Box>
                  <Box>
                    <Box>
                      <Text
                        variant={"caption"}
                        fontWeight={"semibold"}
                        color={"shades.white"}
                        textTransform={"capitalize"}
                      >
                        {message.sender.name}
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        color={"neutral.300"}
                        fontWeight={"400"}
                        style={{
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                        variant={"caption"}
                      >
                        {renderMessage(message.message)}
                      </Text>
                    </Box>
                  </Box>
                </Flex>
              )
          )}
        </Box>
        {showNewMessageNotification && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-end"}
            w={"100%"}
            bgColor={"secondary.300"}
            onClick={scrollToBottom}
            py={"4px"}
            borderTop={"1px solid #32323A"}
          >
            <Flex
              align={"center"}
              cursor={"pointer"}
              justify={"center"}
              gap={2}
            >
              <Text
                textAlign={"center"}
                color={"shades.white"}
                variant={"caption"}
              >
                {t("View New Message")}
              </Text>
              <Ico.ArrowRight
                color="#FFFFFF"
                style={{ transform: "rotate(90deg)" }}
              />
            </Flex>
          </Box>
        )}
        <Box mt={5} mb={{ base: "10", lg: "0" }}>
          <InputChat
            onSendMessage={handleSendMessage}
            placeholder="Envía un mensaje"
            value={inputValue}
            onChange={(value) => setInputValue(value)}
          />
        </Box>
      </Flex>
    </TabPanel>
  );
};

export default LiveChat;
